import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { StyleService } from 'app/styles/services/style-service';
import { StylesConfig } from 'app/styles/types/styles-config';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  //  Public
  public error$ = new BehaviorSubject<string>(null);
  public loginForm: UntypedFormGroup;
  public submitted:boolean = false;
  public passwordTextType: boolean;
  public loading:boolean = false;
  public styles: StylesConfig;
  public dateYear: number = (new Date()).getFullYear();

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private _coreConfigService: CoreConfigService, private _formBuilder: UntypedFormBuilder, private _styleService: StyleService, private _authService: AuthenticationService, private _router: Router) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          menu: {
              hidden: true
          },
          footer: {
              hidden: true
          },
          customizer: false,
          enableLocalStorage: false
      }
    };
  }

  ngOnInit(): void {

    this.loginForm = this._formBuilder.group({
      user_name: ['jaume_valls', [Validators.required]],
      password: ['Ge4qajeu@', Validators.required]
    });

    // Subscribe to config changes
    this._styleService.style.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.styles = config;
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
   togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;

    this._authService.login(this.loginForm.get('user_name').value, this.loginForm.get('password').value).subscribe(
      (result) => {
        //If network do not have 2 Factor Auth code
        if(result.auth_token){
          this._router.navigate(['/samples/home']);
        } else {
          let navigationExtras: NavigationExtras = {
            state: {
              user: result
            }
          };
          this._router.navigate(['/2fa'], navigationExtras);
        }
        //If network have 2 Factor Auth code
      },
      (error) => {
        this.loading = false;
        this.error$.next(error);
      },
      () => {
        this.loginForm.reset();
        this.loading = false;
      }
    );

  }
}
