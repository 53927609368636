import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HttpXsrfTokenExtractor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { PublicModule } from "./main/public/public.module";
import { TextkeysHttpLoader } from "./textkeys/service/textkeys.service";
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from "./auth/helpers";
import { AdminModule } from "./main/admin/admin.module";
import { LandingsModule } from "./main/landings/landings.module";
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TextkeysHttpLoader(httpClient);
}

const appRoutes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "samples",
    loadChildren: () =>
      import("./main/sample/sample.module").then((m) => m.SampleModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./main/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: "landings",
    loadChildren: () =>
      import("./main/landings/landings.module").then((m) => m.LandingsModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./main/public/public.module").then((m) => m.PublicModule),
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    PublicModule,
    AdminModule,
    LandingsModule,

    NgxPageScrollCoreModule.forRoot({ duration: 1600 }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
