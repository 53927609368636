import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { RouterModule } from "@angular/router";
import { LandingsComponent } from "./landings.component";
import { ComparadorComponent } from "./comparador/comparador.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SteperformComponent } from "./steperform/steperform.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";
import { CookiesComponent } from "./cookies/cookies.component";
import { LegalNoticeComponent } from "./legal-notice/legal-notice.component";
import { PrivacyPolicyComponent } from "./privacy_policy/privacy-policy.component";
import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { ReactiveFormsModule } from "@angular/forms";

// routing
const routes: Routes = [
  {
    path: "",
    component: LandingsComponent,
    children: [
      {
        path: "comparador",
        component: ComparadorComponent,
        data: { animation: "auth" },
      },
      {
        path: "steper",
        component: SteperformComponent,
        data: { animation: "auth" },
      },
      {
        path: "cookie-policy",
        component: CookiesComponent,
        data: { animation: "auth" },
      },
      {
        path: "legal-notice",
        component: LegalNoticeComponent,
        data: { animation: "auth" },
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
        data: { animation: "auth" },
      },
      {
        path: "confirmation",
        component: ConfirmationComponent,
        data: { animation: "auth" },
      },
    ],
  },
];

@NgModule({
  declarations: [
    LandingsComponent,
    ComparadorComponent,
    SteperformComponent,
    CookiesComponent,
    LegalNoticeComponent,
    PrivacyPolicyComponent,
    ConfirmationComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    NgSelectModule,
    NgxPageScrollCoreModule,
    ReactiveFormsModule,
  ],
})
export class LandingsModule {}
