<div class="bg-white">
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >Uso de cookies</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >C&oacute;mo utiliza E-RETAIL ADVERTISING S.L. las cookies:</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >E-RETAIL ADVERTISING S.L., en adelante LA AGENCIA, utiliza cookies u
      otros dispositivos de almacenamiento y recuperaci&oacute;n de
      informaci&oacute;n para realizar un seguimiento de las interacciones de
      los usuarios con los productos de LA AGENCIA.<br /><br />Las
      <em>cookies</em> permiten reconocer el navegador de un usuario, as&iacute;
      como el tipo de dispositivo desde el que se accede al sitio web, y se
      utilizan para facilitar la pr&oacute;xima visita del usuario y hacer que
      el sitio web o las aplicaciones resulten m&aacute;s &uacute;tiles.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >Tipos de cookies utilizadas por LA AGENCIA:</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >El sitio web titularidad de LA AGENCIA no utiliza cookies que precisen de
      consentimiento.</span
    >
  </p>

  <!-- <table
    style="
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-decoration: none;
      width: 868pt;
      background-color: white;
      border-collapse: collapse;
      border: none;
    "
  >
    <tbody>
      <tr>
        <td
          colspan="7"
          style="
            border: 1pt inset rgb(102, 102, 102);
            background-color: rgb(249, 249, 249);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red"
                >Cookies Marketing</span
              ></strong
            ><span style="font-size: 13px; color: red"
              >: Cookies para fines de Marketing</span
            >
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="
            border-right-width: 1pt;
            border-bottom-width: 1pt;
            border-left-width: 1pt;
            border-style: none inset inset;
            border-right-color: rgb(102, 102, 102);
            border-bottom-color: rgb(102, 102, 102);
            border-left-color: rgb(102, 102, 102);
            background-color: rgb(238, 238, 238);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red"
                >Dominio bajo el cual figura la cookie</span
              ></strong
            >
          </p>
        </td>
        <td
          style="
            border-style: none inset inset none;
            border-bottom-width: 1pt;
            border-bottom-color: rgb(102, 102, 102);
            border-right-width: 1pt;
            border-right-color: rgb(102, 102, 102);
            background-color: rgb(238, 238, 238);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red">Nombre</span></strong
            >
          </p>
        </td>
        <td
          style="
            border-style: none inset inset none;
            border-bottom-width: 1pt;
            border-bottom-color: rgb(102, 102, 102);
            border-right-width: 1pt;
            border-right-color: rgb(102, 102, 102);
            background-color: rgb(238, 238, 238);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red"
                >Finalidad</span
              ></strong
            >
          </p>
        </td>
        <td
          style="
            border-style: none inset inset none;
            border-bottom-width: 1pt;
            border-bottom-color: rgb(102, 102, 102);
            border-right-width: 1pt;
            border-right-color: rgb(102, 102, 102);
            background-color: rgb(238, 238, 238);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red"
                >Propietario de la cookie</span
              ></strong
            >
          </p>
        </td>
        <td
          style="
            border-style: none inset inset none;
            border-bottom-width: 1pt;
            border-bottom-color: rgb(102, 102, 102);
            border-right-width: 1pt;
            border-right-color: rgb(102, 102, 102);
            background-color: rgb(238, 238, 238);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red">Propia</span></strong
            >
          </p>
        </td>
        <td
          style="
            border-style: none inset inset none;
            border-bottom-width: 1pt;
            border-bottom-color: rgb(102, 102, 102);
            border-right-width: 1pt;
            border-right-color: rgb(102, 102, 102);
            background-color: rgb(238, 238, 238);
            padding: 3pt;
          "
        >
          <p
            style="
              margin-right: 0cm;
              margin-left: 0cm;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              margin: 0cm;
              text-align: center;
            "
          >
            <strong
              ><span style="font-size: 13px; color: red"
                >Caducidad</span
              ></strong
            >
          </p>
        </td>
      </tr>
    </tbody>
  </table> -->
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Mediante la aceptaci&oacute;n de la presente, el Usuario acepta los
      servicios de retargeting que se podr&aacute;n llevar a cabo mediante el
      env&iacute;o de comunicaciones comerciales. El Usuario consiente que
      dichas comunicaciones comerciales contengan dispositivos de almacenamiento
      o cookies de publicidad que se instalar&aacute;n en su navegador.<br /><br />Estos
      servicios de retargeting tienen como finalidad proporcionar m&aacute;s
      informaci&oacute;n sobre productos o servicios que puedan interesar al
      Usuario mediante:</span
    >
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >La instalaci&oacute;n de dispositivos de almacenamiento y
        recuperaci&oacute;n de datos o cookies en equipos terminales en algunos
        de los correos electr&oacute;nicos enviados a los usuarios.</span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm 0cm 0cm 36pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br /><br
    /></span>
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Env&iacute;o de correos electr&oacute;nicos con comunicaciones
        comerciales a los que se haya instalado las Cookies mediante la visita
        de una p&aacute;gina web o mediante la instalaci&oacute;n a
        trav&eacute;s de correo electr&oacute;nico. Adem&aacute;s, en cada
        comunicaci&oacute;n comercial que contenga cookies, se informar&aacute;
        al usuario de qu&eacute; tipo de cookies contiene y c&oacute;mo
        desactivarlas.</span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm 0cm 0cm 36pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br />&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >En todo caso, el usuario, mediante la aceptaci&oacute;n de la
      pol&iacute;tica de protecci&oacute;n de datos y de privacidad del Sitio
      Web, salvo menci&oacute;n expresa en contrario por el usuario, acepta
      expresamente que LA AGENCIA pueda utilizar las cookies. Aun as&iacute;, el
      usuario tiene la posibilidad de configurar su navegador para ser avisado
      de la recepci&oacute;n de cookies y para impedir su instalaci&oacute;n en
      su equipo. Por favor, consulte las instrucciones y manuales de su
      navegador para ampliar esta informaci&oacute;n. Puede obtener m&aacute;s
      informaci&oacute;n acerca del funcionamiento de las cookies en&nbsp;</span
    ><span style="color: black"
      ><a
        href="http://www.youronlinechoices.com/es/"
        style="color: rgb(149, 79, 114); text-decoration: underline"
        ><span
          style="
            font-size: 15px;
            font-family: Lato, sans-serif;
            color: rgb(24, 188, 156);
          "
          >http://www.youronlinechoices.com/es/</span
        ></a
      ></span
    ><span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br /><br />Para obtener m&aacute;s informaci&oacute;n acerca de eMaS
      puede dirigirse al siguiente enlace:</span
    ><span style="color: black"
      ><a
        href="https://emarketingsolutions.es/es/politica-de-cookies/"
        style="color: rgb(149, 79, 114); text-decoration: underline"
        ><span
          style="
            font-size: 15px;
            font-family: Lato, sans-serif;
            color: rgb(24, 188, 156);
          "
          >https://emarketingsolutions.es/es/politica-de-cookies/</span
        ></a
      ></span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >C&oacute;mo desactivar la configuraci&oacute;n de las cookies:</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Todos los navegadores permiten hacer cambios para desactivar la
      configuraci&oacute;n de las cookies. Este es el motivo por el que la
      mayor&iacute;a de navegadores ofrecen la posibilidad de administrar las
      cookies, para obtener un control m&aacute;s preciso sobre la
      privacidad.<br /><br />Estos ajustes se encuentran ubicados en las
      &quot;opciones&quot; o &quot;preferencias&quot; del men&uacute; de su
      navegador.<br /><br />A continuaci&oacute;n podr&aacute; encontrar los
      links de cada navegador para deshabilitar las cookies siguiendo las
      instrucciones:</span
    >
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Internet Explorer (</span
      ><span style="color: black"
        ><a
          href="https://support.microsoft.com/es-es/help/196955/how-to-set-and-customize-cookies-settings-in-internet-explorer-5"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/ksN5y</span
          ></a
        ></span
      ><span style="font-size: 15px; font-family: Lato, sans-serif"
        >)<br /><br />En el men&uacute; de herramientas, seleccione
        <em>&ldquo;Opciones de Internet&rdquo;</em>. Haga clic en la
        pesta&ntilde;a de privacidad. Ver&aacute; un cursor de desplazamiento
        para configurar la privacidad que tiene seis posiciones que le permite
        controlar la cantidad de cookies que se instalar&aacute;n: Bloquear
        todas las cookies, Alta, Media Alto, Media (nivel por defecto), Baja, y
        Aceptar todas las cookies.</span
      >
    </li>
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Mozilla Firefox (</span
      ><span style="color: black"
        ><a
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/F5pHX</span
          ></a
        ></span
      ><span style="font-size: 15px; font-family: Lato, sans-serif"
        >)<br /><br />En el men&uacute; de herramientas, seleccione
        <em>&ldquo;opciones&rdquo;</em>. Seleccione la etiqueta de privacidad en
        el recuadro de opciones. Del men&uacute; desplegable elija &ldquo;usar
        configuraci&oacute;n personalizada para el historial&rdquo;. Esto
        mostrar&aacute; las opciones de cookies y podr&aacute; optar por
        activarlas o desactivarlas marcando la casilla correspondiente.</span
      >
    </li>
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Google Chrome (</span
      ><span style="color: black"
        ><a
          href="https://support.google.com/chrome/answer/95647?hl=en"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/8cAo</span
          ></a
        ></span
      ><span style="font-size: 15px; font-family: Lato, sans-serif"
        >)<br /><br />En el men&uacute; de configuraci&oacute;n, seleccione
        <em>&ldquo;mostrar configuraci&oacute;n avanzada&rdquo;</em> en la parte
        inferior de la p&aacute;gina. Seleccione la tecla de
        &ldquo;configuraci&oacute;n de contenido&rdquo; en la secci&oacute;n de
        privacidad.<br />La secci&oacute;n de la parte superior de la
        p&aacute;gina que aparece le da informaci&oacute;n sobre las cookies y
        le permite fijar las cookies que prefiera. Tambi&eacute;n le permite
        borrar cualquier cookie que tenga almacenada en ese momento.</span
      >
    </li>
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Safari (</span
      ><span style="color: black"
        ><a
          href="https://support.apple.com/kb/PH5042?locale=es_ES"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/KFBFh</span
          ></a
        ></span
      ><span style="font-size: 15px; font-family: Lato, sans-serif"
        >)<br /><br />En el men&uacute; de configuraci&oacute;n, seleccione la
        opci&oacute;n de <em>&ldquo;preferencias&rdquo;</em>. Abra la
        pesta&ntilde;a de privacidad. Seleccione la opci&oacute;n que quiera de
        la secci&oacute;n de <em>&ldquo;bloquear cookies&rdquo;</em>. Recuerde
        que ciertas funciones y la plena funcionalidad del Sitio pueden no estar
        disponibles despu&eacute;s de deshabilitar los cookies.</span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Si desea no ser rastreado por las cookies, Google ha desarrollado un
      complemento para instalar en su navegador al que puede acceder en el
      siguiente enlace:&nbsp;</span
    ><span style="color: black"
      ><a
        href="https://tools.google.com/dlpage/gaoptout"
        style="color: rgb(149, 79, 114); text-decoration: underline"
        ><span
          style="
            font-size: 15px;
            font-family: Lato, sans-serif;
            color: rgb(24, 188, 156);
          "
          >goo.gl/up4ND</span
        ></a
      ></span
    ><span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >.<br />A partir de la opci&oacute;n que tome acerca del uso de cookies en
      los sitios web, se le enviar&aacute; una cookie adicional para
      salvaguardar su elecci&oacute;n y que no tenga que aceptar el uso de
      cookies cada vez que acceda al Sitio Web titularidad de LA AGENCIA.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >Cookies en los dispositivos m&oacute;viles:</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >LA AGENCIA tambi&eacute;n usa cookies u otros dispositivos de
      almacenamiento en dispositivos m&oacute;viles.<br /><br />Al igual que
      sucede en los navegadores de ordenadores, lo navegadores de los
      dispositivos m&oacute;viles permiten realizar cambios en las opciones o
      ajustes de privacidad para desactivar o eliminar las cookies. Si desea
      modificar las opciones de privacidad siga las instrucciones especificadas
      por el desarrollador de su navegador para dispositivo m&oacute;vil. A
      continuaci&oacute;n podr&aacute; encontrar algunos ejemplos de los links
      que le guiar&aacute;n para modificar las opciones de privacidad en su
      dispositivo m&oacute;vil:</span
    >
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >IOS:&nbsp;</span
      ><span style="color: black"
        ><a
          href="https://www.apple.com/es/privacy/"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/pRkla</span
          ></a
        ></span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm 0cm 0cm 36pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br /><br
    /></span>
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Windows Phone:&nbsp;</span
      ><span style="color: black"
        ><a
          href="https://support.microsoft.com/es-es/help/11696/windows-phone-7"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/Rx8QQ</span
          ></a
        ></span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm 0cm 0cm 36pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br /><br
    /></span>
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Chrome Mobile:&nbsp;</span
      ><span style="color: black"
        ><a
          href="https://support.microsoft.com/es-es/help/11696/windows-phone-7"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/Rx8QQ</span
          ></a
        ></span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm 0cm 0cm 36pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br /><br
    /></span>
  </p>
  <ul
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="disc"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Opera Mobile:&nbsp;</span
      ><span style="color: black"
        ><a
          href="https://www.apple.com/es/privacy/"
          style="color: rgb(149, 79, 114); text-decoration: underline"
          ><span
            style="
              font-size: 15px;
              font-family: Lato, sans-serif;
              color: rgb(24, 188, 156);
            "
            >goo.gl/XvmTG</span
          ></a
        ></span
      >
    </li>
  </ul>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm 0cm 0cm 36pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      ><br />&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
    "
  >
    &nbsp;
  </p>
</div>
