import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { JsonFormData } from '../model/json-form-data';

@Injectable({ providedIn: 'root' })
export class CoreJsonFormService {
    private _unsavedChanges = new BehaviorSubject<boolean>(false);
    private _subject = new Subject<JsonFormData>();

    constructor() { }

    get unsavedChanges() {
        return this._unsavedChanges.asObservable();
    }

    get jsonFormData() {
        return this._subject.asObservable();
    }

    public setUnsavedChanges(changes: boolean) {
        this._unsavedChanges.next(changes);
    }

    public setJsonFormData(jsonFormData: JsonFormData) {
        this._subject.next(jsonFormData);
    }

    public clearJsonFormData() {
        this._subject.next();
    }
}