import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { BehaviorSubject, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StylesConfig } from "../types/styles-config";

import * as _ from "lodash";
import { environment } from "environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class StyleService {
  // Private
  public localStyle: StylesConfig;

  private readonly _defaultStyle: StylesConfig;
  private _styleSubject: BehaviorSubject<StylesConfig>;

  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private _translateService: TranslateService
  ) {
    // Set the defaultStyle
    this._defaultStyle = {
      name: "Encuentralo energia",
      footer: "eMarketingSolutions, Online marketing S.L",
      logo: "https://ad.uinterbox.com/logos/emas-logo-mini.png",
      favicon: "/assets/images/pages/comparador/fav.ico",
      html: null,
      css: null,
    };

    // Initialize the config service
    this._initStyle();
  }

  // Get the style
  get style(): any | Observable<any> {
    return this._styleSubject.asObservable();
  }

  private _initStyle(): void {
    let domain: string = location.hostname;

    // Set the style from the default style
    this._styleSubject = new BehaviorSubject(_.cloneDeep(this._defaultStyle));

    // this.httpClient
    //   .post(environment.apiUrl + "/api/public/styles", { dom: domain })
    //   .pipe(
    //     map((response: any) => {
    //       let style: StylesConfig;
    //       style = response.data;
    //       style.html = this.extractHtml(style.html);
    //       return style;
    //     })
    //   )
    //   .subscribe((data) => {
    //     this._styleSubject.next(data);
    //     this.appendCustomCss();
    //   });
  }

  /**
   * Get style
   *
   * @returns {Observable<any>}
   */
  getStyle(): Observable<any> {
    return this._styleSubject.asObservable();
  }

  /**
   * Reset to the default config
   */
  resetConfig(): void {
    this._styleSubject.next(_.cloneDeep(this._defaultStyle));
  }

  /**
   * Append css property to the head of the document
   * https://juristr.com/blog/2019/08/dynamically-load-css-angular-cli/
   */
  private appendCustomCss(): void {
    const head = this.document.getElementsByTagName("head")[0];
    let currentStyle = this._styleSubject.getValue();
    if (currentStyle.css) {
      const style = this.document.createElement("style");
      style.id = "custom-branding-styles";
      style.appendChild(document.createTextNode(String(currentStyle.css)));
      head.appendChild(style);
    }
  }

  /**
   * Extract html value for the given language
   */
  public extractHtml(html: string): string {
    let parser = new DOMParser();
    let pice = parser.parseFromString(html, "text/html");
    let language: string = this._translateService.currentLang.toUpperCase();

    return pice.getElementById(language).innerHTML;
  }
}
