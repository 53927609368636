import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.client;
  }

  /**
   * User login
   *
   * @param user_name
   * @param password
   * @returns user
   */
  login(user_name: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/public/auth/login`, { user_name, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response (Skip 2 Factor Auth)
          if(user){
            if (user.auth_token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));
              // notify
              this.currentUserSubject.next(user.user);
            }
          }
          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    return this._http.get(`${environment.apiUrl}/api/logout`).pipe(
      map(response => {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        // notify
        this.currentUserSubject.next(null);

        return response;
      })
    )
  }

  /**
   * Send user 2fa code
   *
   */
  sendTwoFactorAuthCode(id, code) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/public/auth/checkTwoFACode`, { id, code })
        .pipe(
          map(user => {
            if (user && user?.auth_token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));
              // notify
              this.currentUserSubject.next(user.user);
            }
            return user;
          })
        )
  }

  /**
   * Send user 2fa code
   *
   */
   resendTwoFactorAuthCode(id) {
    return this._http.post<any>(`${environment.apiUrl}/api/public/auth/resendTwoFACode`, { id });
  }

  /**
   * Send user 2fa code
   *
   */
   forgetPassword(email) {
    return this._http.post<any>(`${environment.apiUrl}/api/public/auth/forget-password`,{ email });
  }

  /**
   * resetPassword with token
   *
   */
  resetPassword(password, password_confirmation, token) {
    return this._http.post<any>(`${environment.apiUrl}/api/public/auth/reset-password`,{ password, password_confirmation, token });
  }
}
