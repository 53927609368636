import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class CoreModalService {
    constructor(
        private ngbModal: NgbModal,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    showDefaultModalComponent(theComponent: any, name: any) {
        const componenetFactory = this.componentFactoryResolver.resolveComponentFactory(
          theComponent
        );
        const modalRef = this.ngbModal.open(theComponent, {
            centered: true,
            windowClass: 'modal',
        });
        modalRef.componentInstance.name = name;
        return modalRef;
    }

    showFeaturedDialog(theComponent: any, name: any) {
        const componenetFactory = this.componentFactoryResolver.resolveComponentFactory(
          theComponent
        );
    
        const modalRef = this.ngbModal.open(theComponent, {
            centered: true,
            windowClass: 'modal'
        });
        modalRef.componentInstance.name = name;
        return modalRef;
    }
}
