import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";

export class TextkeysHttpLoader implements TranslateLoader {

    constructor(private httpClient: HttpClient) {}

    public getTranslation(lang: string): Observable<Object> {
        let domain:string =  location.host
        let language: string = navigator.language.split('-')[1];

        let observer:any = new Observable(observer => {
            this.httpClient.post<any>(environment.apiUrl+'/api/public/textkeys',{'lang': language, dom: domain}).pipe(
                map(data => {
                    let textkeysObj: any = {};
                    for (var i = 0; data.data.length > i; i++) {
                        textkeysObj[data.data[i].name] = data.data[i].value;
                    }
                    return textkeysObj;
                })
            ).subscribe(
                data => {
                    observer.next(data);
                    observer.complete();
                }
            )
        });
        return observer;
    }
}