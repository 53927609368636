import { Component, Input, ViewChild } from '@angular/core';
import { CoreJsonFormComponent } from '@core/components/core-json-form/component/core-json-form.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreDialogService } from '../service/core-dialog.service';

@Component({
  selector: 'app-core-dialog-unsaved',
  templateUrl: './core-dialog-unsaved.component.html',
  styleUrls: ['./core-dialog-unsaved.component.scss']
})
export class CoreDialogUnsavedComponent {

  @ViewChild(CoreJsonFormComponent) myForm : CoreJsonFormComponent;

  @Input() name;
  constructor(public activeModal: NgbActiveModal) { }

  public save() {
    // this._coreDialogService.setSavedChanges(true);
  }

}