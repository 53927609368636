import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { AuthenticationService } from "app/auth/service";
import { StyleService } from "app/styles/services/style-service";
import { StylesConfig } from "app/styles/types/styles-config";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class ForgotPasswordComponent implements OnInit {
    public styles: StylesConfig;
    public coreConfig: any;
    public dateYear: number = (new Date()).getFullYear();
    public error: string = '';
    public forgotPassForm: UntypedFormGroup;
    public submitted:boolean = false;
    public loading:boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _coreConfigService: CoreConfigService, private _styleService: StyleService, private _formBuilder: UntypedFormBuilder,private _authService: AuthenticationService, private _router: Router){
        this._unsubscribeAll = new Subject();

        // Configure the layout
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.forgotPassForm.controls;
    }

    ngOnInit(): void {

        this.forgotPassForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
          });

        // Subscribe to styles changes
        this._styleService.style.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.styles = config;
        });

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((style:StylesConfig) => {
            this.coreConfig = style;
        });
    }

    public onSubmit() {
        this.submitted = true;
    
        // stop here if form is invalid
        if (this.forgotPassForm.invalid) {
          return;
        }
    
        // Login
        this.loading = true;

        this._authService.forgetPassword(this.forgotPassForm.get('email').value).subscribe(
            (result) => {
                this._router.navigate(['/public/forgot-password-success']);
            },
            (error) => {
              this.loading = false;
              this.error = error;
            },
            () => {
              this.forgotPassForm.reset();
              this.loading = false;
            }
        );
    }
}