import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { JsonFormData } from '@core/components/core-json-form/model/json-form-data';
import { JsonSettingsData } from '@core/components/core-json-settings-page/model/json-settings-data';

@Injectable({ providedIn: 'root' })
export class NetworkSettingsService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get form users
   */
  getInformationForm() {
    return this._http.get<JsonFormData>(`${environment.apiUrl}/api/admin/network/info`);
  }

  getSettingsPage() {
    return this._http.get<JsonSettingsData>(`${environment.apiUrl}/api/admin/network`);
  }
}
