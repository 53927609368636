import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreJsonFormComponent } from './component/core-json-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
  declarations: [
    CoreJsonFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    CoreCommonModule,
    NgSelectModule,
    RouterModule,
    NgbModule
  ],
  exports: [CoreJsonFormComponent]
})
export class CoreJsonFormModule { }
