import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-twofa',
  templateUrl: './twofa.component.html',
  styleUrls: ['./twofa.component.scss']
})
export class TwofaComponent implements OnInit {

  public user: any;

  otp: string = '';
  inputDigitLeft: string;
  btnStatus: string = "btn-light";
  error: any = '';
  public loading:boolean = false;

  constructor(private _coreConfigService: CoreConfigService, private route: ActivatedRoute, private router: Router, private _translateService: TranslateService, private _authService: AuthenticationService) {
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };

    if (this.router.getCurrentNavigation() != null) {
      if(this.router.getCurrentNavigation().extras.state?.user){
        this.user = this.router.getCurrentNavigation().extras.state?.user;
      } else {
        this.router.navigate(['/']);
      }
    }

    _translateService.get('NET_PUBLIC_TWOFACTORAUTH_7').subscribe((text:string) => {
      this.inputDigitLeft = text;
    });
  }

  public configOptions = {
    length: 6,
    inputClass: 'digit-otp',
    containerClass: 'd-flex justify-content-between'
  }

  ngOnInit(): void { }

  public onOtpChange(event: any) {
    this.otp = event;
    if(this.otp.length < this.configOptions.length) {
      this.inputDigitLeft = this.configOptions.length - this.otp.length + " " + this._translateService.instant('NET_PUBLIC_TWOFACTORAUTH_6');
      this.btnStatus = 'btn-light';
    }

    if(this.otp.length == this.configOptions.length) {
      this.inputDigitLeft = this._translateService.instant('NET_PUBLIC_TWOFACTORAUTH_5');
      this.btnStatus = 'btn-primary';
    }
  }

  public submit(): void {
    
    if(this.otp.length == this.configOptions.length) {
      this._authService.sendTwoFactorAuthCode(this.user.id, this.otp).subscribe((response: any) => {
        this.router.navigate(['/samples/home']);
        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      })
    }
  }

  public resend(): void {
    // Login
    this.loading = true;

    this.error = '';
    this._authService.resendTwoFactorAuthCode(this.user.id).subscribe(()=>{this.loading = false;},
    (error) => {
      this.error = error;
      this.loading = false;
    });
  }

}
