import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges, ViewEncapsulation, Input, ChangeDetectorRef, ViewChild, ElementRef, EventEmitter, Output, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { retry, takeUntil } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from '../model/json-table-data-table-response';
import { SelectionModel } from '@angular/cdk/collections';
import { CoreJsonTableService } from '../service/core-json-table.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-core-json-table',
  templateUrl: './core-json-table.component.html',
  styleUrls: ['./core-json-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreJsonTableComponent implements OnChanges, OnInit {

  @Input() jsonTableData: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @Output() selected = new EventEmitter<any>();
  
  public dtOptions: any = {};
  public model: any;

  public selection = new SelectionModel<Element>(true, []);

  public error: any;
  public deletedRow: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private _http: HttpClient, private cdr: ChangeDetectorRef, private elementRef: ElementRef, private _modalService: NgbModal, private _coreJsonTableService: CoreJsonTableService) {
    this._unsubscribeAll = new Subject();

    this.selection.changed.subscribe(obj => {
      this.selected.emit(this.selection);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const that = this;
    if (!changes.jsonTableData.firstChange) {
      this.initdt(this.jsonTableData.dt, that);
    }
  }

  ngOnInit(): void {
    
  }

  initdt(data, that): void {
    let lastPage=0;  
    let lastSearchText="";
    this.dtOptions = {
      
      language: {
        'emptyTable': data.lang['NET_CORE_JSONTABLE_9'].value,
        'info': data.lang['NET_CORE_JSONTABLE_4'].value,
        'infoEmpty': data.lang['NET_CORE_JSONTABLE_8'].value,
        'infoFiltered': data.lang['NET_CORE_JSONTABLE_6'].value,
        'infoPostFix': '',
        'loadingRecords': data.lang['NET_CORE_JSONTABLE_7'].value,
        'lengthMenu':  data.lang['NET_CORE_JSONTABLE_3'].value,
        'processing': '<div class="d-flex justify-content-center mt-2"><div class="spinner-border" role="status"><span class="visually-hidden"></span></div></div>',
        'search': '',
        'searchPlaceholder': data.lang['NET_CORE_JSONTABLE_19'].value,
        'url': '',
        'zeroRecords': data.lang['NET_CORE_JSONTABLE_8'].value,
        'paginate': {
          'first': '<span class="datatable-icon-prev"></span>',
          'previous': '<span class="datatable-icon-left"></span>',
          'next': '<span class="datatable-icon-right"></span>',
          'last': '<span class="datatable-icon-skip"></span>'
        },
        'aria': {
          'sortAscending': ': ' + data.lang['NET_CORE_JSONTABLE_14'].value,
          'sortDescending': ': ' + data.lang['NET_CORE_JSONTABLE_15'].value,
        }
      },
      dom: "<'row'<'col-md-6 col-12'<'d-flex justify-content-between align-items-center m-1'l>><'col-md-6 col-12 d-flex justify-content-md-end datatable-search-wrapper'<'d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 datatable-search'f>>>" +
      "<'core-datatable'tr>" +
      "<'datatable-footer'<'d-flex justify-content-sm-center w-100'<'page-count d-flex align-items-center'i><'datatable-pager ml-auto mt-50'<'pager'p>>>>",
      pagingType: 'full_numbers',
      pageLength: data.pageLength,
      displayStart: lastPage, // Last Selected Page
      search:{search:lastSearchText}, // Last Searched Text
      serverSide: true,
      processing: true,
      destroy: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.columns = data.columns,
        lastPage = dataTablesParameters.start;  // Note :  dataTablesParameters.start = page count * table length
        lastSearchText = dataTablesParameters.search.value;
        this._http.post<DataTablesResponse>(environment.apiUrl+data.url, dataTablesParameters, {})
        .pipe(
            retry(1)
        ).subscribe((resp:any) => {
          //Notify the compoenent the view has been loaded
          this.cdr.markForCheck();
          this.model = resp;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered
          });
        });
      },
      columnDefs: data.columnDefs,
      ordering: true,
      lengthMenu: data.lengthMenu,
      // columns: data.columns,
      order: data.order,
      headerCallback: (thead: Node, data: any[], start: number, end: number, display: any[]) => {
        // let table = (that.dtElement.el.nativeElement as HTMLElement)?.getBoundingClientRect()?.x;
        // let theadAsHtml = ((thead as HTMLElement).firstChild as HTMLElement);
        // let selectAllInput = this.elementRef.nativeElement.querySelector('.custom-control-input');
        // selectAllInput.classList.remove('sorting')
        // let test = this.sanitizer.bypassSecurityTrustHtml('<my-test-component name="dynamic"></my-test-component>');
        //Select all from header event listener
        // if(selectAllInput){
        //   selectAllInput.addEventListener("click", () => {
        //     this.masterToggle();
        //     this.cdr.markForCheck();
        //   });

        //   selectAllInput.addEventListener("change", () => {
        //     this.selection.hasValue() && this.isAllSelected()
        //   });
        // }
        
        //Calculate stiky width and fix the header
        // for (let index = 0; index < that.jsonTableData.dt.columns.length; index++) {
        //   if(that.jsonTableData.dt.columns[index].core?.settings.sticky) {
        //     const { x } = (theadAsHtml[index] as HTMLElement).getBoundingClientRect();
        //     (theadAsHtml[index] as HTMLElement).style.position = 'sticky';
        //     (theadAsHtml[index] as HTMLElement).style.left = `${x - table}px`;
        //     (theadAsHtml[index] as HTMLElement).style.zIndex = '11';
        //   }
        // }
      },
      initComplete: (settings, json) => {
        // settings.aoColumns = data.columns
      },
      drawCallback: (settings) => {
        // Code to hide pager if no results
        // BUG fix: Code to hide dataTables_empty message when table have records
        const pager = (that.elementRef.nativeElement.querySelector('.pager')) as HTMLElement;
        const emptyRecords =  (that.elementRef.nativeElement.querySelector('.dataTables_empty')) as HTMLElement;
        let display: string;

        settings.fnRecordsDisplay() ? display = 'block' : display = 'none';
        pager.style.display = display;
        display === 'block' ? emptyRecords.style.display = 'none' : emptyRecords.style.display = 'compact';
      }
    }
  }

  public open(event) {
    // Prevent row to be selected in dropdown
    event.stopPropagation();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return this.model.data.every(row => this.selection.isSelected(row.id));
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.model.data.forEach(row => this.selection.deselect(row.id));
    } else {
      this.model.data.forEach(row => this.selection.select(row.id));
    }
  }

  // modal Open Danger
  mOpenDel(mDel, dt) {
    this.deletedRow = dt;
    this._modalService.open(mDel, {
      centered: true,
      windowClass: 'modal modal-danger',
    });
  }

  deleteRecord() {
    let url:string = ''
    this.jsonTableData.dt.columns.forEach(col => {
      if(col.data == 'actions') {
        url = col.core.settings.actions.delete.url;
        return
      }
    });
    
    this._coreJsonTableService.deleteRecord(url.replace('{id}', this.deletedRow.id)).pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.ajax.reload();
      })
    },(error) => {
      this.error = error;
    })
  }

}
