import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { Routes } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TwofaComponent } from "./twofa/twofa/twofa.component";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";
import { NgOtpInputModule } from "ng-otp-input";
import { PublicComponent } from "./public.component";
import { ForgotPasswordComponent } from "./forgot_password/forgot-password.component";
import { ForgotPasswordSuccessComponent } from "./forgot_password_success/forgot-password-success.component";
import { ResetPasswordFormComponent } from "./reset_password_form/reset-password-form.component";
import { ResetPasswordSuccessComponent } from "./reset_password_success/reset-password-success.component";
import { RegisterComponent } from "./register/register.component";
import { CoreJsonFormModule } from "@core/components";

// routing
const routes: Routes = [
  {
    path: "public",
    component: PublicComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        data: { animation: "auth" },
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        data: { animation: "auth" },
      },
      {
        path: "forgot-password-success",
        component: ForgotPasswordSuccessComponent,
        data: { animation: "auth" },
      },
      {
        path: "reset-password-form",
        component: ResetPasswordFormComponent,
        data: { animation: "auth" },
      },
      {
        path: "reset-password-success",
        component: ResetPasswordSuccessComponent,
        data: { animation: "auth" },
      },
      {
        path: "register",
        component: RegisterComponent,
        data: { animation: "auth" },
      },
    ],
  },
  {
    path: "",
    redirectTo: "landings/comparador",
    pathMatch: "full",
  },

  {
    path: "2fa",
    component: TwofaComponent,
    data: { animation: "auth" },
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    TwofaComponent,
    PublicComponent,
    ForgotPasswordComponent,
    ForgotPasswordSuccessComponent,
    ResetPasswordFormComponent,
    ResetPasswordSuccessComponent,
    RegisterComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CardSnippetModule,
    NgOtpInputModule,
    CoreJsonFormModule,
  ],
})
export class PublicModule {}
