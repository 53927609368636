<table datatable [dtOptions]="dtOptions" class="table table-hover" *ngIf="jsonTableData" stickyTable>
    <thead>
        <tr>
            <th *ngFor="let col of jsonTableData?.dt.columns; let colIndex = index" sticky [isSticky]="col.core?.settings.sticky" class="sorting_disabled">
                <div class="custom-control custom-checkbox" *ngIf="['select'].includes(col.data)">
                    <input type="checkbox" class="custom-control-input" [indeterminate]="selection.hasValue() && !isAllSelected()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" id="headerChkbxRef"/><label class="custom-control-label" for="headerChkbxRef"></label>
                </div>
                <div *ngIf="!['select'].includes(col.data)">
                    {{col.title}}
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dt of model?.data; let rowIndex = index" [ngClass]="{'rowSelected' : dt?.select}">
            <td *ngFor="let column of jsonTableData?.dt.columns;" sticky [isSticky]="column.core?.settings.sticky">
                <div *ngIf="column.data === 'select'">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            [checked]="selection.isSelected(dt.id)"
                            (change)="$event ? selection.toggle(dt.id) : null"
                            [id]="'rowChkbxRef'+rowIndex"
                            (click)="$event.stopPropagation()"
                        />
                        <label class="custom-control-label" [for]="'rowChkbxRef'+rowIndex"></label>
                    </div>
                </div>
                <div *ngIf="
                    ['name'].includes(column.data)
                ">
                    <div class="d-flex align-items-center">
                        <div
                            class="avatar mr-1 ml-0"
                            [ngClass]="{
                                'bg-light-primary': dt.status == '1',
                                'bg-light-success': dt.status == '2',
                                'bg-light-danger': dt.status == '0',
                                'bg-light-warning': dt.status == '3',
                                'bg-light-info': dt.status == '5'
                            }"
                        >
                            <div class="avatar-content">{{dt[column.data] | initials }}</div>
                        </div>
                
                        <div class="cell-line-height">
                            <span class="text-muted font-small-2">{{ dt[column.data] }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="['status'].includes(column.data)">
                    <div
                        class="badge badge-pill"
                        [ngClass]="{
                        'badge-light-primary': dt[column.data] == '1',
                        'badge-light-success': dt[column.data] == '2',
                        'badge-light-danger': dt[column.data] == '0',
                        'badge-light-warning': dt[column.data] == '4',
                        'badge-light-info': dt[column.data] == '5'
                        }"
                    >
                        {{
                        dt[column.data] == 1
                            ? 'Active'
                            : status == 2
                            ? 'Professional'
                            : status == 3
                            ? 'Rejected'
                            : status == 4
                            ? 'Resigned'
                            : status == 5
                            ? 'Applied'
                            : 'Deleted'
                        }}
                    </div>
                </div>
                <div *ngIf="['email','user_name'].includes(column.data)">
                    {{dt[column.data]}}
                </div>
                <div *ngIf="['created_at','updated_at'].includes(column.data)">
                    {{dt[column.data].display}}
                </div>
                <div *ngIf="['role'].includes(column.data)">
                    {{dt[column.data]}}
                </div>
                <div *ngIf="['surname'].includes(column.data)">
                    {{dt[column.data]}}
                </div>
                <div class="d-flex align-items-center" *ngIf="['actions'].includes(column.data)">
                    <div *ngFor="let action of dt[column.data]; let actionIndex = index">
                        <button type="button" class="btn p-0" [disabled]="!dt['status']" *ngIf="['edit'].includes(action)" [attr.alt]="column.core?.settings.actions.edit.textkey | translate" [routerLink]="[(column.core?.settings.actions.edit.url).replace('{id}',dt.id)]"><i [data-feather]="column.core?.settings.actions.edit.icon" class="text-primary cursor-pointer"></i></button>

                        <div
                            *ngIf="['delete'].includes(action)"
                            ngbDropdown 
                            container="body"
                        >
                            <button
                                [disabled]="!dt['status']"
                                ngbDropdownToggle
                                class="btn hide-arrow p-0"
                                id="dropdownBrowserState"
                                data-toggle="dropdown"
                                (click)="open($event)"
                            >
                                <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                    </button>
                            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                                <a ngbDropdownItem  (click)="mOpenDel(mDel, dt)" class="d-flex align-items-center" *ngIf="action === 'delete'" [attr.alt]="column.core?.settings.actions.delete.textkey | translate"
                                  ><i [data-feather]="column.core?.settings.actions.delete.icon" class="mr-50"></i> {{column.core?.settings.actions.delete.textkey | translate}}</a
                                >
                            </div>
                        </div>
                        <div
                            *ngIf="['delete'].includes(action)"
                        >
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #mDel let-modal>
    <div class="modal-header">
        <h5 class="modal-title ml-50 text-dark font-weight-bolder" id="myModalLabel160">{{ 'NET_CORE_JSONTABLE_20' | translate }}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      {{ 'NET_CORE_JSONTABLE_21' | translate }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" rippleEffect>
            {{ 'NET_CORE_JSONFORM_3' | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="modal.close('Accept click'); deleteRecord()" rippleEffect>
            {{ 'NET_CORE_JSONTABLE_18' | translate }}
        </button>
    </div>
</ng-template>