import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreJsonSettingsPageComponent } from './component/core-json-settings-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CoreJsonFormModule } from '../core-json-form/core-json-form.module';
import { BlockUIModule } from 'ng-block-ui';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CoreJsonSettingsPageComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgxSkeletonLoaderModule,
    CoreJsonFormModule,
    BlockUIModule.forRoot(),
    TranslateModule
  ],
  exports: [
    CoreJsonSettingsPageComponent
  ]
})
export class CoreJsonSettingsPageModule { }
