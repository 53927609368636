import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  RendererFactory2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { CoreConfig } from "@core/types";
import {
  NgbCarousel,
  NgbModal,
  NgbSlideEvent,
} from "@ng-bootstrap/ng-bootstrap";
import Stepper from "bs-stepper";
import moment from "moment";

@Component({
  selector: "app-steperform",
  templateUrl: "./steperform.component.html",
  styleUrls: ["./steperform.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SteperformComponent implements OnInit {
  private modernVerticalWizardStepper: Stepper;
  private horizontalWizardStepper: Stepper;

  public coreConfig: CoreConfig;
  public personalInfo: any;

  private renderer2: Renderer2;
  private scriptsLoaded: boolean = false;

  isFormVisible = false;
  hidden = false;
  timer;
  questions: any = [];
  initialStepsAnsw;
  ip;
  leadCount = 0;

  colaboradores = [
    {
      id: 1,
      title: "Repsol",
      checked: null,
      touch: false,
    },
    {
      id: 2,
      title: "NEXUS",
      checked: null,
      touch: false,
    },
    {
      id: 3,
      title: "Escandinava de Electricidad",
      checked: null,
      touch: false,
    },
    {
      id: 4,
      title: "Holaluz",
      checked: null,
      touch: false,
    },
  ];

  submitted = false;
  isSubmited = false;
  images = [
    "https://tarifalo.com/comparador/imgs/companiashd/endesa.png",
    "https://tarifalo.com/comparador/imgs/companiashd/aldro.png",
    "https://tarifalo.com/comparador/imgs/companiashd/edp.png",
    "https://tarifalo.com/comparador/imgs/companiashd/endesa.png",
    "https://tarifalo.com/comparador/imgs/companiashd/energygo.png",
    "https://tarifalo.com/comparador/imgs/companiashd/esfera.png",
    "https://tarifalo.com/comparador/imgs/companiashd/holaluz.png",
    "https://tarifalo.com/comparador/imgs/companiashd/iberdrola.png",
    "https://tarifalo.com/comparador/imgs/companiashd/imagina.png",
    "https://tarifalo.com/comparador/imgs/companiashd/naturgy.png",
    "https://tarifalo.com/comparador/imgs/companiashd/podo.png",
    "https://tarifalo.com/comparador/imgs/companiashd/repsol.png",
    "https://tarifalo.com/comparador/imgs/companiashd/lucera.png",
  ];

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  @ViewChild("carousel", { static: false }) carousel: NgbCarousel;

  //Form
  public firstForm = new FormGroup({
    power: new FormControl("", Validators.required),
    howMuchPaid: new FormControl("", Validators.required),
  });

  public secondForm = new FormGroup({
    zipcode: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
    age: new FormControl("", Validators.required),
  });

  public thirdForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    surname: new FormControl("", [Validators.required]),
    phone: new FormControl("", [
      Validators.required,
      Validators.maxLength(9),
      Validators.minLength(9),
      Validators.pattern("^(\\+34|0034|34)?[67]\\d{8}$"),
    ]),
    email: new FormControl("", [Validators.required, Validators.email]),
    terms: new FormControl(false, Validators.pattern("true")),
    colaboradores: new FormControl(),
    // customCheck10: new FormControl(false, Validators.pattern("true")),
  });

  constructor(
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private _router: Router,
    private _http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    private rendererFactory2: RendererFactory2
  ) {
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);
    // Configure the layout
    this._coreConfigService.config = {
      app: {
        appName: "encuentralo.energia",
        appTitle: "encuentralo.energia",
      },
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };

    if (
      typeof this._router.getCurrentNavigation().extras.state !== "undefined"
    ) {
      this.initialStepsAnsw =
        this._router.getCurrentNavigation().extras.state.compareForm;
    } else {
      this._router.navigate(["comparador"]);
    }
  }

  private insertMainScript() {
    const script: HTMLScriptElement = this.renderer2.createElement("script");
    script.type = "text/javascript";
    script.text = `        
        window._tfa = window._tfa || [];
        window._tfa.push({notify: 'event', name: 'page_view', id: 1757722});
        !(function (t, f, a, x) {
          if (!document.getElementById(x)) {
            t.async = 1;
            t.src = a;
            t.id = x;
            f.parentNode.insertBefore(t, f);
          }
        })(
          document.createElement("script"),
          document.getElementsByTagName("script")[0],
          "//cdn.taboola.com/libtrc/unip/1757722/tfa.js",
          "tb_tfa_script"
        );
    `;
    this.renderer2.appendChild(this._document.body, script);
  }

  ngOnInit(): void {
    this.insertMainScript();

    this._http
      .get<any>(
        "https://api-eretail.uintertool.com/api/transactionoverview?api_token=hHGpORIGc0Jwd8ay03mgynr8WBmCzqk7S7tALG8iKcfShWgCsQ94xj81K776&start_date=" +
          moment().format("YYYY-MM-DD") +
          "&end_date=" +
          moment().format("YYYY-MM-DD") +
          "&format=json&acts=18484"
      )
      .subscribe((response: any) => {
        this.leadCount = response.data.report.length;
      });

    this._http
      .get<any>("https://geolocation-db.com/json/")
      .subscribe((response: any) => {
        this.ip = response.IPv4;
      });
    this.modernVerticalWizardStepper = new Stepper(
      document.querySelector("#stepper4"),
      {
        linear: true,
        animation: true,
      }
    );
  }

  onSubmit(value) {
    this.questions.push(value);
    this.isSubmited = true;
    this.isFormVisible = true;
    this.timer = setTimeout(this.toggleForm.bind(this), 3000);
  }

  toggleForm() {
    this.hidden = true;
    setTimeout(this.buildStepperForm.bind(this), 1000);
  }

  buildStepperForm() {
    this.horizontalWizardStepper = new Stepper(
      document.querySelector("#stepper1"),
      {}
    );
  }

  modernVerticalPrevious() {
    this.modernVerticalWizardStepper.previous();
  }

  modernVerticalNext(value) {
    this.questions.push(value);
    this.modernVerticalWizardStepper.next();
  }

  onSlide(slideEvent: NgbSlideEvent) {
    console.log(slideEvent);
  }

  horizontalWizardStepperNext(data) {
    this.horizontalWizardStepper.next();
  }

  openVerticallyCentered(content) {
    this.submitted = true;
    console.log(this.thirdForm.value);
    
    // Obtener campos inválidos
    const invalidFields = this.getInvalidFields();
    console.log('Campos inválidos:', invalidFields);
    
    // stop here if form is invalid
    if (this.thirdForm.invalid) {
      return;
    }

    if (!this.thirdForm.value.terms) {
      this.modalService.open(content, {
        centered: true,
        size: "md",
      });
    } else {
      this.firePixel();
    }
  }

  openCol(c) {
    this.modalService.open(c, {
      centered: true,
      size: "md",
      backdrop: false,
    });
  }

  firePixel() {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json; charset=utf-8");
    headers.set("Authorization", "ecc921ac-a129-489e-a320-cee3a6291304");
    const idLead = Math.random().toString().split(".")[1];

    if (this.leadCount <= 40) {
      this._http
        .get("https://eretail.uinterbox.com/tracking/cnv", {
          params: new HttpParams()
            .set("typ", "s2s")
            .set("org", 3686)
            .set("evt", 8335)
            .set("act", 18484)
            .set("pub", 345)
            .set("fi7", this.thirdForm.value.phone)
            .set("fi1", this.thirdForm.value.name)
            .set("fi2", this.thirdForm.value.surname)
            .set("cid_e", this.thirdForm.value.email)
            .set("ue2", this.initialStepsAnsw.company)
            .set("ue1", JSON.stringify(this.colaboradores))
            .set("fi4", '')
            .set("fi6", this.secondForm.value.zipcode),
        })
        .subscribe(
          () => {},
          (error) => {
            // if(typeof (window as any).fbq === 'function'){
            //   (window as any).fbq('track', 'CompleteRegistration');
            // }
            this._router.navigate(["confirmation"]);
          }
        );
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkColInputs() {
    if (this.thirdForm.value.colaboradores) {
      this.colaboradores.forEach((col) => {
        if (!col.touch) {
          col.checked = true;
        }
      });
    } else {
      this.colaboradores.forEach((col) => {
        if (!col.touch) {
          col.checked = false;
        }
      });
    }
  }

  colInputs(col) {
    col.touch = true;
  }

  getInvalidFields(): string[] {
    const invalidFields: string[] = [];
    Object.keys(this.thirdForm.controls).forEach(key => {
      const control = this.thirdForm.get(key);
      if (control.invalid) {
        invalidFields.push(key);
      }
    });
    return invalidFields;
  }
}
