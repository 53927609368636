import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoreJsonTableService {
    //public
    public currentExport: Observable<any>;
    //private
    private currentExportSubject: BehaviorSubject<any>;

    constructor(private _http: HttpClient) {
        this.currentExportSubject = new BehaviorSubject<any>([]);
        this.currentExport = this.currentExportSubject.asObservable();
    }

    add(obj) {
        this.currentExportSubject.next(obj);
    }

    remove(nom: Object) {
        const newArray = [...this.currentExportSubject.value];
        newArray.forEach((item, index) => {
            if (item === nom) { newArray.splice(index, 1); }
        });
        this.currentExportSubject.next(newArray)
    }

    deleteRecord(url: string) {
        return this._http.delete(environment.apiUrl + '/api' + url);
    }
}