import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { CoreConfig } from "@core/types";
import { Subject } from "rxjs";

@Component({
  selector: "app-landings-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent implements OnInit {
  public coreConfig: CoreConfig;
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private _coreConfigService: CoreConfigService) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      app: {
        appName: "encuentralo.energia",
        appTitle: "encuentralo.energia",
      },
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit(): void {}

  checkAll = () => {
    for (var i = 1; i < 22; i++) {
      let element: any = document.getElementById("customCheck" + i);
      element.checked = !element.checked;
    }
  };
}
