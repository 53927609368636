import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JsonFormControls } from '../model/json-form-controls';
import { JsonFormData } from '../model/json-form-data';
import { CoreJsonFormService } from '../service/core-json-form.service';

@Component({
  selector: 'app-core-json-form',
  templateUrl: './core-json-form.component.html',
  styleUrls: ['./core-json-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreJsonFormComponent implements OnChanges {
  
  @Output() childFormSubmit = new EventEmitter<any>();
  
  @Input() jsonFormData: JsonFormData;

  public myForm: UntypedFormGroup;
  public submitted: boolean = false;
  public passwordTextType: boolean;
  public loading$ = new BehaviorSubject<boolean>(false);
  public error: any;

  // private
  private _unsubscribeAll: Subject<any>;

  constructor(private fb: UntypedFormBuilder, private _http: HttpClient, private _router: Router, private _coreJsonFormService: CoreJsonFormService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetForm();
    if (changes.jsonFormData && !changes.jsonFormData.firstChange) {
      if(typeof this.jsonFormData?.controls !== 'undefined'){
        this.createForm(this.jsonFormData.controls);
      }
    }

    this.myForm.statusChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((d)=> {
      this._coreJsonFormService.setUnsavedChanges(true);
    });
  }

  get f() {
    return this.myForm.controls;
  }

  resetForm(): void {
    this.myForm = this.fb.group({});
  }

  isRequired(controls): boolean{
    for (const [key, value] of Object.entries(controls)) {
      if(key === "required"){
        return true;
      }
    }
    return false;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  createForm(controls: JsonFormControls[]) {
    for (const control of controls) {
      const validatorsToAdd = [];
      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value.regex));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }
      this.myForm.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd)
      );
    } 
  }

  setFormErrors(error) {
    if(typeof error === 'object'){
      for (const [key, value] of Object.entries(error)) {
        if(typeof this.f[key] !== 'undefined'){
          this.f[key].setErrors({invalid: value});
        }
      }
    }
    else {
      this.error = error;
    }
    this.loading$.next(false);
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.myForm.invalid) {
      return;
    }

    this.loading$.next(true);
    this.error = null;


    if(this.jsonFormData.actions.submit.url.includes("store")){
      this._http.post(environment.apiUrl + this.jsonFormData.actions.submit.url, this.myForm.value).subscribe((response:any) => {
        let navigationExtras: NavigationExtras = {
          state: {
            response
          }
        };
        if(typeof this.jsonFormData.actions.cancel?.url !== 'undefined') {
          this.myForm.reset();
          this._router.navigate([this.jsonFormData?.actions.cancel.url], navigationExtras);
        }
      },(error) => {
        this.setFormErrors(error);
      },
      () => {
        this._coreJsonFormService.setUnsavedChanges(false);
        this.myForm.reset();
        this.loading$.next(false);
      })
    }

    if(this.jsonFormData.actions.submit.url.includes("edit")){
      this._http.put(environment.apiUrl + this.jsonFormData.actions.submit.url, this.myForm.value).subscribe((response:any) => {
        let navigationExtras: NavigationExtras = {
          state: {
            response
          }
        };
        if(this.jsonFormData){
          if(typeof this.jsonFormData.actions.cancel?.url !== 'undefined') {
            this.myForm.reset();
            this._router.navigate([this.jsonFormData?.actions.cancel.url], navigationExtras);
          }
        }
      },(error) => {
        this.setFormErrors(error);
      },
      () => {
        this._coreJsonFormService.setUnsavedChanges(false);
        this.loading$.next(false);
      })
    }

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
