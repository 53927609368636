<div class="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
    <div class="col-lg-4 col-md-7 mx-auto my-auto">
        <div class="card">
            <div class="card-body px-lg-4 py-lg-4">
                <div class="d-flex justify-content-center">
                    <div class="icon-block">
                        <img src="https://img.icons8.com/ios/50/2E9AFE/lock-2.png"/>
                    </div>
                </div>
                <div class="text-center my-4">
                    <h2 class="fw-bold">{{'NET_PUBLIC_TWOFACTORAUTH_1' | translate}} {{user?.name}}</h2>
                    <p class="text-muted">{{'NET_PUBLIC_TWOFACTORAUTH_2' | translate}} <strong>{{user?.email}}</strong></p>
                </div>
                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error | translate }}</p>
                    </div>
                </ngb-alert>
                <div class="my-4">
                    <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="configOptions"></ng-otp-input>
                </div>
                <div class="">
                    <div class="d-flex align-items-center justify-content-center w-100 text-center">
                        <div class="w-auto">
                            <p class="text-left mb-0">{{'NET_PUBLIC_TWOFACTORAUTH_4' | translate}} 
                                <a (click)="resend()" class="font-weight-bolder text-primary"><span *ngIf="loading" class="spinner-border spinner-border-sm ml-1"></span>{{'NET_PUBLIC_TWOFACTORAUTH_3' | translate}}</a>
                            </p>
                        </div>
                        <div class="ml-auto">
                            <button class="btn" [ngClass]="btnStatus" (click)="submit()">{{ this.inputDigitLeft }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>