<div class="modal-header">
    <h5 class="modal-title ml-50 text-dark font-weight-bolder">{{ 'NET_CORE_MODAL_1' | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  {{ 'NET_CORE_MODAL_2' | translate }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Close click')" rippleEffect>
        {{ 'NET_CORE_JSONFORM_3' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Save click')" rippleEffect>
        {{ 'NET_CORE_JSONFORM_4' | translate }}
    </button>
</div>