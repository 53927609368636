import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
    private issuer = {
      login: `${environment.apiUrl}/api/public/auth/login`,
    };

    constructor() {}

    public handleData(token: any) {
      localStorage.setItem('auth_token', token);
    }

    public getToken() {
      return localStorage.getItem('auth_token');
    }

    // Verify the token
    public isValidToken() {
      const token = this.getToken();
      if (token) {
        const payload = this.payload(token);
        if (payload) {
          return Object.values(this.issuer).indexOf(payload.iss) > -1
            ? true
            : false;
        }
      } else {
        return false;
      }
    }

    private payload(token: any) {
      const jwtPayload = token.split('.')[1];
      return JSON.parse(atob(jwtPayload));
    }

    // User state based on valid token
    public isLoggedIn() {
      return this.isValidToken();
    }

    // Remove token
    public removeToken() {
      localStorage.removeItem('auth_token');
    }
}