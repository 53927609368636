import { Role } from './role';

export class User {
  name: string;
  surname: string;
  email: string;
  user_name: string;
  auth_token: string;
  role: Role;
}
