import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { JsonFormData } from '@core/components/core-json-form/model/json-form-data';
import { JsonSettingsData } from '../model/json-settings-data';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CoreJsonSettingPageService } from '../service/core-json-setting-page.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { takeUntil } from 'rxjs/operators';
import { CoreModalService } from '@core/services/modal.service';
import { CoreDialogUnsavedComponent } from '@core/components/core-dialog/core-dialog-unsaved/core-dialog-unsaved.component';
import { TranslateService } from '@ngx-translate/core';
import { CoreJsonFormService } from '@core/components/core-json-form/service/core-json-form.service';

@Component({
  selector: 'app-core-json-settings-page',
  templateUrl: './core-json-settings-page.component.html',
  styleUrls: ['./core-json-settings-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreJsonSettingsPageComponent implements OnChanges {

  @Input() jsonSettingsData: [JsonSettingsData];
  @ViewChild('nav', {static: false}) nav: any;
  @ViewChild('form', {static: false}) form: any;
  @BlockUI('card-section') cardBlockUI: NgBlockUI;

  public active: String | Element = '';
  public formData$ = new BehaviorSubject<JsonFormData>(null);
  public isDisabled: boolean = false;
  public error: any;

  // private
  private _unsubscribeAll: Subject<any>;
  private unsaved: boolean;
  private loadingString: Type<Text>;

  constructor(private _coreJsonSettingPageService: CoreJsonSettingPageService, private customModal: CoreModalService, private _translateService: TranslateService, private _coreJsonFormService: CoreJsonFormService) {
    this._unsubscribeAll = new Subject();

    this._translateService.get('NET_CORE_JSONTABLE_7').pipe(takeUntil(this._unsubscribeAll)).subscribe((text: Type<Text>) => {
      this.loadingString = text
    });

    this._coreJsonFormService.unsavedChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((val: boolean) => {
      this.unsaved = val;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jsonSettingsData && !changes.jsonSettingsData.firstChange) {
      this.loadP(this.jsonSettingsData[0].name);
    }
  }

  onNavChange($event: NgbNavChangeEvent) {
    if(this.unsaved) {
      const a = this.customModal.showFeaturedDialog(CoreDialogUnsavedComponent, "unsaved");
      a.result.then((data) => {
        this.form.onSubmit();
        this.loadP($event.nextId);
      }, (reason) => {
        this._coreJsonFormService.setUnsavedChanges(false);
        this.loadP($event.nextId);
      });
    } else {
      this.loadP($event.nextId);
    }
  };

  loadP(event: string) {
    let url: string = '';

    this.formData$.next(null);
    this.active = event;
    this.isDisabled = true;
    this.cardBlockUI.start(this.loadingString);

    this.jsonSettingsData.forEach(el => {
      if(el.name == event) {
        url = el.url;
        return;
      }
    });
    this._coreJsonSettingPageService.fetchForm(url).pipe(takeUntil(this._unsubscribeAll)).subscribe((data:any) => {
        this.formData$.next(data);
        this.isDisabled = false;
        this.cardBlockUI.stop();
      },
      (error) => {
        this.error = error;
      },
      () => {
      }
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
