import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Optional,
} from '@angular/core';
import { StickyTableDirective } from './sticky-table.directive';

@Directive({
    selector: '[sticky]',
})
export class StickyDirective implements AfterViewInit {
    @Input() isSticky: boolean;
    constructor(
      private el: ElementRef,
      @Optional() private table: StickyTableDirective
    ) {}
  
    ngAfterViewInit() {
      if(this.isSticky) {
        const el = this.el.nativeElement as HTMLElement;
        const { x } = el.getBoundingClientRect();
        el.style.position = 'sticky';
        el.style.left = this.table ? `${x - (this.table.x)}px` : '0px';

        /** Wrong value x from el */
      }
    }
}