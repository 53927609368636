<div class="row justify-content-center w-100">
    <div class="col-11 col-sm-10 col-md-11 col-lg-10 col-xl-11 col-xxl-12">
        <header>
            <a [routerLink]="['/public/login']">
                <img src="{{ styles.logo }}" alt="brand-logo" height="28"/>
            </a>
        </header>
        <div>
            <h1>{{'NET_PUBLIC_FORGOTPASSRESET_1' | translate}}</h1>
            <hr>
            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                <div class="alert-body">
                    <p>{{ error | translate }}</p>
                </div>
            </ngb-alert>
            <form class="auth-login-form mt-2" [formGroup]="resetPassForm" (ngSubmit)="onSubmit()">
                <fieldset class="mb-1">
                    <div class="form-group col-12 col-md-6 pl-0">
                        <label class="form-label" for="reset-password">{{'NET_PUBLIC_LOGIN_5' | translate}}</label>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input
                                [type]="passwordTextType ? 'text' : 'password'"
                                formControlName="password"
                                class="form-control form-control-merge"
                                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                                placeholder="············"
                                aria-describedby="reset-password"
                                tabindex="1"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                    <i class="feather font-small-4"
                                        [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                        (click)="togglePasswordTextType()">
                                    </i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12 col-md-6 pl-0">
                        <label class="form-label" for="reset-password-2">{{'NET_PUBLIC_FORGOTPASSRESET_2' | translate}}</label>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input
                                [type]="passwordTextType ? 'text' : 'password'"
                                formControlName="password_confirmation"
                                class="form-control form-control-merge"
                                [ngClass]="{ 'is-invalid error': submitted && f.password_confirmation.errors }"
                                placeholder="············"
                                aria-describedby="reset-password-2"
                                tabindex="1"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                    <i class="feather font-small-4"
                                        [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                        (click)="togglePasswordTextType()">
                                    </i>
                                </span>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex">
                            <button [disabled]="loading" class="btn btn-primary btn-lg" tabindex="2" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'NET_PUBLIC_FORGOTPASS_3' | translate}}
                            </button>
                            <a class="btn btn-link" [routerLink]="['/public/login']"> {{'NET_PUBLIC_FORGOTPASS_4' | translate}} </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>