<div class="bg-white">
    <div>
        <p>
            <em>INSTRUCCIONES:</em>
            <em>
                El presente Aviso Legal deberá poder ser consultado por todos los
                usuarios del Web. Se aconseja que se coloque un link o enlace en la
                página de inicio que lleve por título “Aviso Legal” y que al hacer
                clic en ello, pueda leerse.
            </em>
        </p>
        <h5>
            <strong>AVISO LEGAL</strong>
        </h5>
        <p>
            <strong></strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <h4>
            Identidad del titular del Sitio Web
        </h4>
        <p>
            El presente aviso e información legal regula el uso del servicio del
            Web de Internet bajo el siguiente nombre de dominio:
            https://encuentralo.energia
        </p>
        <p>
            ENCUÉNTRALO.ENERGIA es el nombre comercial que se incluye en el sitio
            Web arriba indicado y objeto del presente aviso legal
            ENCUÉNTRALO.ENERGIA, como prestador de servicios de la sociedad de la
            información se rige por lo establecido en la Ley 34/2002, de 11 de
            julio, de Servicios de la Sociedad de la Información y Comercio
            Electrónico.
        </p>
        <p>
            ENCUÉNTRALO.ENERGIA es un nombre comercial propiedad de E-RETAIL
            ADVERTISING, S.L. con domicilio en C/ Ayala 85, 1ºC, 28006 Madrid. El
            correo electrónico de atención al cliente es
            info@e-retailadvertising.com y el número de teléfono de contacto es
            911453242. La sociedad se encuentra registrada en el Registro Mercantil
            de Madrid, Tomo 25591, Folio 200, Sección 8, Hoja M461231 y NIF
            B85442283.
        </p>
        <p>
            El Web es propiedad de E-RETAIL ADVERTISING, S.L., quien se reserva la
            facultad de efectuar, en cualquier momento y sin previo aviso,
            modificaciones de la información contenida en su Web o en la
            configuración y presentación del mismo. El titular posee todos los
            derechos de autor sobre todos los contenidos del Web, ya sean por
            derecho propio o por cesiones o acuerdos con terceros.
        </p>
        <p>
            Por el acceso a este Web, incluidas todas sus páginas, se acepta que
            ENCUÉNTRALO.ENERGIA no será responsable de consecuencia alguna, daño o
            perjuicio que se derive de dicho acceso o uso de la información de
            dicho Web o por el acceso a otras materias en Internet a través de las
            conexiones con este Web. Tampoco será responsable y el usuario lo
            acepta de los errores o problemas informáticos causados por virus,
    gusanos, software malicioso u otros ataques de ingeniería social (        <em>phising, pharming, troyanos, etc.</em>) que terceros puedan haber
            ubicado maliciosamente en nuestros servidores, durante el margen de
            tiempo hasta que se logren poner en marcha las medidas de protección
            reactiva de que dispone esta entidad. Si el usuario se percata de
            errores en el funcionamiento o de comportamientos extraños del sitio,
            puede dirigir un correo a la dirección arriba indicada. Se prohíbe el
            acceso a los contenidos de este Sitio Web por medio de sistemas
            mecanizados que sean distintos a personas físicas, ya que pueden
            ocasionar al titular del Web daños al no poder medir con objetividad
            las audiencias.
        </p>
        <p>
            <strong>Condiciones de uso del Web:</strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            Los contenidos e informaciones que se suministran a través de
            https://encuentralo.energia cumplen con la legislación vigente y son
            propiedad de E-RETAIL ADVERTISING, S.L. o adquiridos o explotados con
            títulos legítimos.
        </p>
        <p>
    La utilización del Web atribuye la condición de        <strong>usuario</strong> del Web e implica la aceptación plena y sin
            reservas de todas y cada una de las disposiciones incluidas en este
            Aviso Legal en la versión publicada por E-RETAIL ADVERTISING, S.L. en
            el momento mismo en que el Usuario acceda al Web. En consecuencia, el
            Usuario debe leer atentamente el presente Aviso Legal en cada una de
            las ocasiones en que se proponga utilizar el Web, ya que aquél puede
            sufrir modificaciones.
        </p>
        <p>
            El Usuario se compromete a utilizar el Web, los contenidos y servicios
            de conformidad con la Ley, el presente Aviso Legal, la política de
            privacidad, las buenas costumbres y el orden público. Del mismo modo,
            el Usuario se obliga a no utilizar el Web o los servicios que se
            presten a través de él con fines o efectos ilícitos o contrarios al
            contenido del presente Aviso Legal, lesivos de los intereses o derechos
            de terceros, o que de cualquier forma pueda dañar, inutilizar o
            deteriorar el Web o sus servicios, o impedir un normal disfrute del Web
            por otros Usuarios.
        </p>
        <p>
            Asimismo, el Usuario se compromete expresamente a no destruir, alterar,
            inutilizar o, de cualquier otra forma, dañar los datos, programas o
            documentos electrónicos y demás que se encuentren en la presente Web.
        </p>
        <p>
            El Usuario se compromete a no introducir programas, virus, macros,
            controles o cualquier otro dispositivo lógico o secuencia de caracteres
            que causen o sean susceptibles de causar cualquier tipo de alteración
            en los sistemas informáticos del titular del Web o de terceros.
        </p>
        <p>
            El Usuario acepta y declara conocer el presente Aviso legal y Política
            de Privacidad. En caso contrario, el usuario debe abandonar el Sitio
            Web en este momento.
        </p>
        <p>
            La actividad no está sujeta a ningún régimen de autorización
            administrativa previa, ni adherida a códigos de conducta, salvo a la
            buena fe, la costumbre y al Código civil. El titular no presta
            servicios de intermediación o alojamiento.
        </p>
        <p>
            <strong>Publicidad</strong>
        </p>
        <p>
            El Sitio web puede albergar contenidos publicitarios o estar
            patrocinado. Los anunciantes y patrocinadores son los únicos
            responsables de asegurarse que el material remitido para su inclusión
            en el Sitio web cumple con las leyes y consentimientos que en cada caso
            puedan ser de aplicación. El titular del Web no será responsable de
            daños a la sensibilidad que el material publicitario u otro material
            pueda causar a los usuarios o visitantes del Web. Para información de
            menores, consulte la Política de Privacidad.
        </p>
        <p>
            <strong>Propiedad Intelectual e Industrial</strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            Todos los contenidos del Web, salvo que se indique lo contrario, son
            titularidad exclusiva del titular de la web o de terceros y, con
            carácter enunciativo, que no limitativo, el diseño gráfico, código
            fuente, logos, textos, gráficos, ilustraciones, fotografías, y demás
            elementos que aparecen en la Web.
        </p>
        <p>
            Igualmente, todos los nombres comerciales, marcas o signos distintivos
            de cualquier clase contenidos en el Web están protegidos por la Ley.
        </p>
        <p>
            El titular del Web no concede ningún tipo de licencia o autorización de
            uso personal al Usuario o visitante sobre sus derechos de propiedad
            intelectual e industrial o sobre cualquier otro derecho relacionado con
            su Web y los servicios ofrecidos en la misma.
        </p>
        <p>
            Todos los derechos reservados. El Usuario o visitante reconoce que la
            reproducción, distribución, comercialización, transformación, y en
            general, cualquier otra forma de explotación, por cualquier
            procedimiento, de todo o parte de los contenidos de este Web constituye
            una infracción de los derechos de propiedad intelectual y/o industrial
            del titular del Web o del titular de estos, y un delito del Código
            penal, y queda prohibido.
        </p>
        <h4>
            Responsabilidad por el Uso del Sitio Web
        </h4>
        <p>
            El Usuario es el único responsable de las infracciones en las que pueda
            incurrir o de los perjuicios que pueda causar por la utilización del
            Web o de sus contenidos, quedando el titular del Web, sus socios,
            proveedores, colaboradores, empleados y representantes, exonerados de
            cualquier clase de responsabilidad que se pudiera derivar por las
            acciones del Usuario.
        </p>
        <p>
            El titular del Web empleará todos los esfuerzos y medios razonables
            para facilitar información actualizada y fehaciente en el Web.
        </p>
        <p>
            El Usuario será responsable frente a cualquier reclamación o acción
            legal, judicial o extrajudicial, iniciada por terceras personas contra
            el titular del Web basada en la utilización por el Usuario del Web de
            este Web o de sus contenidos para sus propios propósitos ajenos a los
            de este Web. En caso en que así lo acuerde la autoridad judicial o
            administrativa que sea competente, el Usuario asumirá cuantos gastos,
            costes e indemnizaciones sean irrogados al titular de la Web con motivo
            de tales reclamaciones o acciones legales.
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <h4>
            Responsabilidad por el funcionamiento del Sitio Web
        </h4>
        <p>
            El titular del Sitio Web excluye toda responsabilidad que se pudiera
            derivar de interferencias, omisiones, interrupciones, virus
            informáticos, averías telefónicas o desconexiones en el funcionamiento
            operativo del sistema electrónico, motivado por causas ajenas al
            titular de la Web.
        </p>
        <p>
            Asimismo, el titular del Sitio Web también excluye cualquier
            responsabilidad que pudiera derivarse por retrasos o bloqueos en el
            funcionamiento operativo de este sistema electrónico causado por
            deficiencias o sobre carga en las líneas telefónicas o en Internet, así
            como de daños causados por terceras personas mediante intromisiones
            ilegitimas fuera del control del titular del Web.
        </p>
        <p>
            El titular del Web está facultado para suspender temporalmente, y sin
            previo aviso, la accesibilidad al Web con motivo de operaciones de
            mantenimiento, reparación, actualización o mejora.
        </p>
        <h4>
            Responsabilidad por Enlaces o Links
        </h4>
        <p>
            <strong></strong>
        </p>
        <p>
            El titular del Web está exento de cualquier responsabilidad respecto a
            la información que se halle fuera del Web, ya que la función de los
            links que aparecen es únicamente la de informar al Usuario sobre la
            existencia de otras fuentes de información sobre un tema en concreto o
            de publicidad.
        </p>
        <p>
            <strong>
                Política respecto a los Datos Personales obtenidos a través del Web
            </strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            Sin perjuicio de lo previsto en lo indicado en cada uno de los
            formularios del Web, cuando el Usuario facilite sus datos de carácter
            personal está autorizando expresamente al titular de la Web al
            tratamiento de sus datos personales para las finalidades que en estos
            se indiquen. El titular del Web tratará los datos para fines que han
            sido previamente informados de modo claro y transparente, con una base
            jurídica debidamente identificada e informada, y operará con ellos
            adoptando medidas de seguridad técnicas y organizativas adecuadas a los
            riesgos previamente identificados, cumpliendo con El Reglamento (UE)
            2016/679, general de protección de datos.
        </p>
        <p>
            El Usuario o su representante podrá ejercitar sus derechos de acceso
            incluido el derecho a obtener copia de los datos personales objeto de
            tratamiento, rectificación, oposición, cancelación o borrado en su
            caso, portabilidad y limitación del tratamiento que le asisten,
            mediante solicitud escrita y firmada dirigida al domicilio indicado en
            el apartado "Política de Privacidad" del presente Web.
        </p>
        <p>
            Se le informa también que los datos de IP (Internet Protocol) del
            abonado desde el cual se conecta a nuestra página van a ser conservados
            durante los plazos establecidos en la legislación vigente, tras el cual
            serán automáticamente borrados, para una finalidad de seguridad y
            colaboración con la Justicia.
        </p>
        <p>
            <strong>Modificaciones de las condiciones de uso</strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            El titular del Web se reserva el derecho a modificar, desarrollar o
            actualizar en cualquier momento y sin previa notificación, las
            condiciones de uso del presente Web. El Usuario quedará obligado
            automáticamente por las condiciones de uso que se hallen vigentes en el
            momento en que acceda al Web, por lo que deberá leer periódicamente
            dichas condiciones de uso.
        </p>
        <p>
            <strong>Resolución de conflictos y jurisdicción competente</strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            Todas las controversias que pudieran surgir en cuanto a la aplicación
            del presente aviso legal, serán dirimidas ante los Juzgados y
            Tribunales que correspondan según legislación vigente. En caso de no
            tratarse de consumidores, serán dirimidas en los
        </p>
        <p>
            Juzgados y Tribunales del domicilio del titular del Web o del domicilio
            del consumidor si el destinatario del servicio tuviera tal condición.
        </p>
        <p>
            Tiene a su disposición hojas de reclamaciones en el establecimiento
            mercantil de este web, o solicitándolas al correo electrónico de
            contacto que aparece en este Aviso legal.
        </p>
        <p>
            En caso de reclamaciones y quejas sobre los trabajos o servicios
            realizados, disponemos de un medio propio para poder recibir y atender
            dichas quejas o reclamaciones, a través del correo electrónico de
            contacto que aparece en este Aviso legal.
        </p>
    </div>
    <br clear="all"/>
</div>