import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  Renderer2,
  Inject,
  RendererFactory2,
} from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-landings-comparador",
  templateUrl: "./comparador.component.html",
  styleUrls: ["./comparador.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ComparadorComponent implements OnInit, OnDestroy, AfterViewInit {
  public coreConfig: any;
  public selectedOption: string;
  @ViewChild("content") content: any;
  @ViewChild("privacy") privacy: any;
  public hidden = false;
  // Private
  private _unsubscribeAll: Subject<any>;

  private renderer2: Renderer2;
  private scriptsLoaded: boolean = false;

  //Form
  public selectorForm = new FormGroup({
    selectedOption: new FormControl("luz"),
    // typeOfClient: new FormControl("", Validators.required),
    // area: new FormControl("", Validators.required),
    // province: new FormControl("", Validators.required),
    company: new FormControl("", Validators.required),
  });

  constructor(
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private rendererFactory2: RendererFactory2
  ) {
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      app: {
        appName: "encuentralo.energia",
        appTitle: "encuentralo.energia",
      },
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };

    this.selectorForm.valueChanges.subscribe((data) => {
      console.log("Form changes", data);
      this.onSubmit();
    });
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, {
      centered: true,
      size: "md",
    });
  }

  openPrivacy(content) {
    this.modalService.open(content, {
      centered: true,
      size: "md",
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.insertMainScript();
    }, 10000);
  }

  private insertMainScript() {
    const script: HTMLScriptElement = this.renderer2.createElement("script");
    script.type = "text/javascript";
    script.text = `
        _tfa.push({notify: 'event', name: 'VisitaCualificada', id: 1757722});
    `;
    this.renderer2.appendChild(this._document.body, script);
  }

  private insertOnSubmitScript() {
    const script: HTMLScriptElement = this.renderer2.createElement("script");
    script.type = "text/javascript";
    script.text = `
      _tfa.push({notify: 'event', name: 'InicioFormulario', id: 1757722});
    `;
    this.renderer2.appendChild(this._document.body, script);
  }

  ngAfterViewInit(): void {
    this.openVerticallyCentered(this.content);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  onSubmit() {
    this.insertOnSubmitScript();
    // if (!this.selectorForm.valid) {
    //   return;
    // }

    let navigationExtras: NavigationExtras = {
      state: {
        compareForm: this.selectorForm.value,
      },
    };
    this._router.navigate(["/landings/steper"], navigationExtras);
  }

  ngOnDestroy() {}
}
