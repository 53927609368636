import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DataTablesModule } from "angular-datatables";
import { CsvModule } from '@ctrl/ngx-csv';
import { CoreJsonFormModule, CoreJsonSettingsPageModule } from '@core/components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    data: { animation: 'account-settings' }
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
];

@NgModule({
  declarations: [
    SettingsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NgbModule,
    CoreCommonModule,
    ContentHeaderModule,
    DataTablesModule, 
    CsvModule,
    CoreJsonFormModule,
    NgxSkeletonLoaderModule,
    CoreJsonSettingsPageModule
  ]
})
export class NetworkModule { }
