import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'NET_ADMIN_HOME_1',
    translate: 'NET_ADMIN_HOME_1',
    type: 'item',
    icon: 'home',
    classes: '',
    url: 'samples/home',
  },
  {
    id: 'operations',
    type: 'collapsible',
    title: 'NET_ADMIN_OPERATIONS_1',
    translate: 'NET_ADMIN_OPERATIONS_1',
    icon: 'archive',
    classes: 'menu-option-title',
    children: [
      {
        id: 'clients',
        title: 'NET_ADMIN_CLIENTS_1',
        translate: 'NET_ADMIN_CLIENTS_1',
        type: 'item',
        icon: 'circle',
        classes: '',
        url: 'admin/client/clients'
      },
      {
        id: 'publishers',
        title: 'NET_ADMIN_PUBLISHERS_1',
        translate: 'NET_ADMIN_PUBLISHERS_1',
        type: 'item',
        icon: 'circle',
        classes: '',
        url: 'admin/operations/publishers'
      }
    ]
  },
  {
    id: 'reporting',
    type: 'collapsible',
    title: 'NET_ADMIN_REPORTING_1',
    translate: 'NET_ADMIN_REPORTING_1',
    classes: 'menu-option-title',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'overview',
        title: 'NET_ADMIN_REPORTING_2',
        translate: 'NET_ADMIN_REPORTING_2',
        type: 'item',
        icon: 'circle',
        classes: '',
        url: 'admin/reporting/overview'
      }
    ]
  },
  {
    id: 'billing',
    type: 'collapsible',
    title: 'NET_ADMIN_BILLING_1',
    translate: 'NET_ADMIN_BILLING_1',
    classes: 'menu-option-title',
    icon: 'dollar-sign',
    children: [
      {
        id: 'invoices',
        title: 'NET_ADMIN_BILLING_2',
        translate: 'NET_ADMIN_BILLING_2',
        type: 'item',
        icon: 'circle',
        classes: '',
        url: 'admin/billing/invoices'
      }
    ]
  },
  {
    id: 'network',
    type: 'collapsible',
    title: 'NET_SIDEBAR_NETWORK',
    translate: 'NET_SIDEBAR_NETWORK',
    classes: 'menu-option-title',
    icon: 'server',
    children: [
      {
        id: 'settings',
        title: 'NET_SIDEBAR_NETWORK_1',
        translate: 'NET_SIDEBAR_NETWORK_1',
        type: 'item',
        icon: 'circle',
        classes: '',
        url: 'admin/network/settings'
      },
      {
        id: 'users',
        title: 'NET_SIDEBAR_NETWORK_2',
        translate: 'NET_SIDEBAR_NETWORK_2',
        type: 'item',
        icon: 'circle',
        classes: '',
        url: 'admin/network/users'
      },
    ]
  }
]
