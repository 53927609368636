<form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="form" [class]="jsonFormData?.group.class">
    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
        <div class="alert-body">
            <p>{{ error }}</p>
        </div>
    </ngb-alert>
    <div class="row" *ngFor="let control of jsonFormData?.controls">
        <div class="col-12">
            <div class="form-group">
                <label [for]="control.label" *ngIf="!['toggle'].includes(control.type)">{{ control.label | translate }}:</label>
                <label *ngIf="isRequired(control.validators) && !['toggle'].includes(control.type)" class="form-label form-required ml-50" [attr.for]="control.label"> {{'NET_CORE_JSONFORM_2' | translate}}</label>
                    <input
                        *ngIf="
                            [
                                'text',
                                'email',
                                'number',
                                'search',
                                'tel',
                                'url'
                            ].includes(control.type)
                        "
                        [type]="control.type"
                        [formControlName]="control.name"
                        [value]="control.value"
                        class="form-control w-100"
                        [ngClass]="{ 'is-invalid':submitted && f[control.name].errors }"
                    />
<!--                     
                    <input 
                        *ngIf="
                            [
                                'toggle'
                            ].includes(control.type)
                        "
                        type="checkbox" 
                        class="custom-control-input" 
                        [formControlName]="control.name"
                        [checked]="control.value"
                    /> -->

                    <div class="custom-control custom-control-primary custom-switch" *ngIf="['toggle'].includes(control.type)">
                        <div class="d-flex justify-content-between">
                            <p class="mb-50">{{ control.label | translate }}</p>
                            <input type="checkbox" [formControlName]="control.name" [checked]="control.value" class="custom-control-input" [id]="'customSwitch'+control.name" />
                            <label class="custom-control-label" [for]="'customSwitch'+control.name"></label>
                        </div>
                    </div>

                    <ng-select 
                        *ngIf="['select'].includes(control.type)" 
                        [items]="control.dropdown" 
                        bindLabel="name" 
                        bindValue="id" 
                        [formControlName]="control.name" 
                        [value]="control.value" 
                        [clearable]="false" 
                        [searchable]="false"
                        [ngClass]="{ 'is-invalid': submitted && f[control.name].errors }"
                    ></ng-select>

                    <div class="input-group input-group-merge form-password-toggle" *ngIf="['password'].includes(control.type)" [ngClass]="{ 'is-invalid error': submitted && f[control.name].errors }">
                        <input
                            [type]="passwordTextType ? 'text' : 'password'"
                            [formControlName]="control.name"
                            [value]="control.value"
                            class="form-control form-control-merge"
                            [ngClass]="{ 'is-invalid error': submitted && f[control.name].errors }"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text cursor-pointer">
                                <i class="feather font-small-4"
                                    [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                    (click)="togglePasswordTextType()">
                                </i>
                            </span>
                        </div>
                    </div>

                    <span *ngIf="submitted && f[control.name].errors" class="invalid-feedback">
                        <div *ngIf="f[control.name].errors.required">{{'NET_ADMIN_VALIDATOR_1' | translate}}</div>
                        <div *ngIf="f[control.name].errors.email">{{'NET_ADMIN_VALIDATOR_2' | translate}}</div>
                        <div *ngIf="f[control.name].errors.minlength">{{('NET_ADMIN_VALIDATOR_3' | translate).replace('_MIN_', control.validators.minLength)}}</div>
                        <div *ngIf="f[control.name].errors.maxlength">{{('NET_ADMIN_VALIDATOR_3' | translate).replace('_MAX_', control.validators.minLength)}}</div>
                        <div *ngIf="f[control.name].errors.invalid">{{f[control.name].errors.invalid}}</div>
                        <div *ngIf="f[control.name].errors.pattern">{{control.validators.pattern.message | translate}}</div>
                    </span>
            </div>
        </div>
    </div>
    <div class="col-12 p-0 mt-50 d-flex">
        <div class="form-group ml-auto">
            <button [disabled]="loading$ | async" *ngIf="jsonFormData?.actions.submit?.label" class="btn btn-primary btn-lg" type="submit" rippleEffect>
                <span *ngIf="loading$ | async" class="spinner-border spinner-border-sm mr-1"></span>{{jsonFormData?.actions.submit.label | translate}}
            </button>
            <button *ngIf="jsonFormData?.actions.cancel?.label" (click)="myForm.reset()" type="button" [routerLink]="[jsonFormData?.actions.cancel.url]" class="btn btn-outline-secondary btn-lg ml-1" rippleEffect>                
                {{ jsonFormData?.actions.cancel.label | translate }}
            </button>
        </div>
    </div>
</form>