<div class="row justify-content-center w-100">
    <div class="col-11 col-sm-10 col-md-11 col-lg-10 col-xl-11 col-xxl-12">
        <header class="w-75 ml-auto">
            <a [routerLink]="['/public/login']">
                <img src="{{ styles.logo }}" alt="brand-logo" height="28"/>
            </a>
        </header>
        <div class="w-75 ml-auto">
            <h1>{{'NET_PUBLIC_REGISTER_1' | translate}}</h1>
            <hr>
            <p>{{'NET_PUBLIC_REGISTER_2' | translate}} <strong>{{'NET_PUBLIC_REGISTER_3' | translate}}</strong></p>

            <!-- Register Form -->
            <app-core-json-form [jsonFormData]="formData"></app-core-json-form>
            <!-- Register Form -->
            <div class="row">
                <a class="btn btn-link" [routerLink]="['/public/login']"> {{'NET_PUBLIC_FORGOTPASS_4' | translate}} </a>
            </div>
        </div>
    </div>
</div>