<div class="container-fluid h-100 unauth-wrapper">
    <div class="row h-100">
        <aside class="col-12 col-lg-5 pos-lg-full-height order-2 order-lg-1 big-panel">
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-11 col-lg-10 col-xl-8 col-xxl-7" [innerHTML]="styles.html | safe: 'html'">
                </div>
            </div>
        </aside>
        <div class="col-12 offset-lg-4 col-lg-7 order-1 order-lg-2 big-panel pos-lg-full-height">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>