<section id="page-account-settings">
    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
        <div class="alert-body">
            <p>{{ error }}</p>
        </div>
    </ngb-alert>
    <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0" *ngIf="jsonSettingsData">
            <ul ngbNav #nav="ngbNav" class="nav nav-pills flex-column text-nowrap" (navChange)="onNavChange($event)" [(activeId)]="active" *ngFor="let data of jsonSettingsData; let i = index">
                <li ngbNavItem [ngbNavItem]="data.name" [destroyOnHide]="true">
                    <a ngbNavLink [class]="data.class" [class]="{'disabled': isDisabled}">
                        <i data-feather="info" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">{{data.label | translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-md-9 mb-2 mb-md-0" *blockUI="'card-section'">
            <div class="card">
                <div class="card-body">
                    <!-- <ngx-skeleton-loader count="9" appearance="line" *ngIf="!(formData$ | async)"></ngx-skeleton-loader> -->
                    <app-core-json-form #form [jsonFormData]="formData$ | async"></app-core-json-form>
                </div>
            </div>
        </div>
    </div>
</section>