import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { AuthenticationService } from "app/auth/service";
import { StyleService } from "app/styles/services/style-service";
import { StylesConfig } from "app/styles/types/styles-config";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class ResetPasswordFormComponent implements OnInit {
    public styles: StylesConfig;
    public coreConfig: any;
    public dateYear: number = (new Date()).getFullYear();
    public error: string = '';
    public resetPassForm: UntypedFormGroup;
    public submitted:boolean = false;
    public loading:boolean = false;
    public passwordTextType: boolean;
    public token: string = '';

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _coreConfigService: CoreConfigService, private _styleService: StyleService, private _formBuilder: UntypedFormBuilder,private route: ActivatedRoute, private _authService: AuthenticationService, private _router: Router){
        this._unsubscribeAll = new Subject();

        // Configure the layout
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.resetPassForm.controls;
    }

    ngOnInit(): void {
        // Subscribe to styles changes
        this._styleService.style.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.styles = config;
        });

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((style:StylesConfig) => {
            this.coreConfig = style;
        });

        this.resetPassForm = this._formBuilder.group({
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required],
        }, {
            validator: this.confirmedValidator('password', 'password_confirmation')
        });

        this.route.queryParams
            .subscribe(params => {
                this.token = params.token;
                console.log(this.token);
            }
        );
    }

    confirmedValidator(controlName: string, matchingControlName: string){
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }

    /**
     * Toggle password
     */
    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    public onSubmit() {
        this.submitted = true;
    
        // stop here if form is invalid
        if (this.resetPassForm.invalid) {
          return;
        }
    
        // Login
        this.loading = true;

        this._authService.resetPassword(this.resetPassForm.get('password').value, this.resetPassForm.get('password_confirmation').value, this.token).subscribe(
            (result) => {
                this._router.navigate(['/public/reset-password-success']);
            },
            (error) => {
              this.loading = false;
              this.error = error;
            },
            () => {
              this.resetPassForm.reset();
              this.loading = false;
            }
        );
    }
}