<div class="container h-100 d-flex align-items-center justify-content-center">
  <!-- Modern Vertical Wizard -->
  <div class="card w-100">
    <section class="w-100">
      <div class="card-body">
        <h1 class="modal-title">¡MUCHAS GRACIAS!</h1>
        <h2 class="text-muted mt-1">
          En breve nos pondremos en contacto contigo para conseguirte las
          mejores ofertas
        </h2>
        <a
          class="btn btn-warning btn-lg mt-1"
          [routerLink]="['/landings/comparador']"
          >Volver al inicio</a
        >
      </div>
    </section>
  </div>
</div>

<ng-template #thankyoupage let-modal>
  <div class="modal-body">
    <h1 class="modal-title">¡MUCHAS GRACIAS!</h1>
    <h1 class="modal-title">
      EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO PARA CONSEGUIRTE LAS MEJORES
      OFERTAS.
    </h1>
    <p>
      <a
        [routerLink]="['/landings/comparador']"
        (click)="modal.dismiss('Cross click')"
        >Volver al inicio</a
      >
    </p>
  </div>
</ng-template>
