import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { StyleService } from 'app/styles/services/style-service';
import { StylesConfig } from 'app/styles/types/styles-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class PublicComponent implements OnInit {

    public styles: StylesConfig;
    public coreConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _coreConfigService: CoreConfigService, private _styleService: StyleService){
        this._unsubscribeAll = new Subject();

        // Configure the layout
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    ngOnInit(): void {

        // Subscribe to styles changes
        this._styleService.style.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.styles = config;
        });

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((style:StylesConfig) => {
            this.coreConfig = style;
        });
    }
    
}