import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { CoreJsonTableComponent } from './component/core-json-table.component';
import { StickyTableDirective } from "./directives/sticky-table.directive";
import { StickyDirective } from "./directives/sticky.directive";

@NgModule({
  declarations: [
    CoreJsonTableComponent,
    StickyDirective,
    StickyTableDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DataTablesModule,
    CoreCommonModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    CoreJsonTableComponent,
    StickyDirective,
    StickyTableDirective
  ]
})
export class CoreJsonTableModule { }
  