<div class="row justify-content-center w-100">
    <div class="col-11 col-sm-10 col-md-11 col-lg-10 col-xl-11 col-xxl-12">
        <header>
            <a href="/">
                <img src="{{ styles.logo }}" alt="brand-logo" height="28"/>
            </a>
        </header>
        <div>
            <h1>{{'NET_PUBLIC_FORGOTPASS_1' | translate}}</h1>
            <hr>
            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                <div class="alert-body">
                    <p>{{ error | translate }}</p>
                </div>
            </ngb-alert>
            <!-- ForgotPass Form -->
            <form class="auth-login-form mt-2" [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()">
                <fieldset class="mb-1">
                    <div class="row form-row">
                        <div class="form-group col-12 col-md-6">
                            <label class="form-label" for="login-email">{{'NET_PUBLIC_FORGOTPASS_2' | translate}}</label>
                            <input
                                type="text"
                                formControlName="email"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                placeholder="email@datracks.io"
                                aria-describedby="login-email"
                                autofocus=""
                                tabindex="1"
                            />
                        </div>
                    </div>
                </fieldset>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex">
                            <button [disabled]="loading" class="btn btn-primary btn-lg" tabindex="2" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'NET_PUBLIC_FORGOTPASS_3' | translate}}
                            </button>
                            <a class="btn btn-link" [routerLink]="['/public/login']"> {{'NET_PUBLIC_FORGOTPASS_4' | translate}} </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>