import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { CoreConfig } from '@core/types';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-landings-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class CookiesComponent implements OnInit {
    public coreConfig: CoreConfig;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _coreConfigService: CoreConfigService){
        this._unsubscribeAll = new Subject();

        // Configure the layout
        this._coreConfigService.config = {
            app: {
                appName: 'encuentralo.energia',
                appTitle: 'encuentralo.energia',
            },
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    ngOnInit(): void {
        
    }
}