import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonFormData } from '@core/components/core-json-form/model/json-form-data';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class CoreJsonSettingPageService {
    constructor(private _http: HttpClient) {
    }

    public fetchForm(url) {
        return this._http.get<JsonFormData>(environment.apiUrl + url);
    }
}