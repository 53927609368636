<div class="row justify-content-center w-100">
    <div class="col-11 col-sm-10 col-md-11 col-lg-10 col-xl-11 col-xxl-12">
        <header>
            <a href="/">
                <img src="{{ styles.logo }}" alt="brand-logo" height="28"/>
            </a>
        </header>
        <div>
            <h1>{{'NET_PUBLIC_LOGIN_1' | translate}}</h1>
            <p class="subtitle mb-1">
                {{'NET_PUBLIC_LOGIN_2' | translate}}
                <a class="font-weight-bolder" [routerLink]="['/public/register']">{{'NET_PUBLIC_LOGIN_3' | translate}}</a>
            </p>
            <hr>
            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error$ | async">
                <div class="alert-body">
                    <p>{{ error$ | async | translate }}</p>
                </div>
            </ngb-alert>
            <!-- Login Form -->
            <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <fieldset class="mb-1">
                    <div class="row form-row">
                        <div class="form-group col-12 col-md-6">
                            <label class="form-label" for="login-email">{{'NET_PUBLIC_LOGIN_4' | translate}}</label>
                            <input
                                type="text"
                                formControlName="user_name"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.user_name.errors }"
                                placeholder="datracks"
                                aria-describedby="login-email"
                                autofocus=""
                                tabindex="1"
                            />
                        </div>
                        <div class="form-group col-12 col-md-6">
                            <label class="form-label" for="login-password">{{'NET_PUBLIC_LOGIN_5' | translate}}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                                <input
                                    [type]="passwordTextType ? 'text' : 'password'"
                                    formControlName="password"
                                    class="form-control form-control-merge"
                                    [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                                    placeholder="············"
                                    aria-describedby="login-password"
                                    tabindex="2"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text cursor-pointer">
                                        <i class="feather font-small-4"
                                            [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                            (click)="togglePasswordTextType()">
                                        </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="row">
                    <div class="col-12">
                        <button [disabled]="loading" class="btn btn-primary btn-lg" tabindex="4" rippleEffect>
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'NET_PUBLIC_LOGIN_6' | translate}}
                        </button>
                        <a class="btn btn-link" [routerLink]="['/public/forgot-password']"> {{'NET_PUBLIC_LOGIN_7' | translate}} </a>
                    </div>
                </div>
            </form>
            <!-- /Login Form -->
        </div>
        <div class="noauth-footer">© {{ dateYear }} {{ styles.footer }}</div>
    </div>
</div>