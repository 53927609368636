import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { JsonFormData } from "@core/components/core-json-form/model/json-form-data";
import { CoreConfigService } from "@core/services/config.service";
import { StyleService } from "app/styles/services/style-service";
import { StylesConfig } from "app/styles/types/styles-config";
import { environment } from "environments/environment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {

  public styles: StylesConfig;
    public coreConfig: any;
    public dateYear: number = (new Date()).getFullYear();
    public formData: JsonFormData;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _coreConfigService: CoreConfigService, private _styleService: StyleService, private http: HttpClient){
        this._unsubscribeAll = new Subject();

        // Configure the layout
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    ngOnInit(): void {
        // Subscribe to styles changes
        this._styleService.style.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.styles = config;
        });

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((style:StylesConfig) => {
            this.coreConfig = style;
        });

        this.http.get(environment.apiUrl + '/api/public').subscribe((formData: JsonFormData) => {
          this.formData = formData;
        })
    }

}
