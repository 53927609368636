import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";

import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService
  ) {
    this.onApiDataChange = new BehaviorSubject("");
    // if(this._authService.currentUserValue){
    //   this.getNotificationsData();
    // }
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(environment.apiUrl + "/api/admin/notifications/index")
        .subscribe((response: any) => {
          this.apiData = response;
          this.onApiDataChange.next(this.apiData);
          resolve(this.apiData);
        }, reject);
    });
  }
}
