import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkModule } from './network/network.module';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from 'app/auth/helpers';

const routes: Routes = [
  {
    path: 'network',
    loadChildren: () => import('./network/network.module').then(m => m.NetworkModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
];


@NgModule({
  declarations: [
   
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NetworkModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ]
})
export class AdminModule { }
