import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation,
} from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { CoreConfig } from "@core/types";
import { Subject } from "rxjs";

@Component({
  selector: "app-landings",
  templateUrl: "./landings.component.html",
  styleUrls: ["./landings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LandingsComponent implements OnInit {
  public coreConfig: CoreConfig;
  public selectedOption: string;

  private renderer2: Renderer2;
  private scriptsLoaded: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    @Inject(DOCUMENT) private _document: Document,
    private rendererFactory2: RendererFactory2
  ) {
    this._unsubscribeAll = new Subject();
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);

    // Configure the layout
    this._coreConfigService.config = {
      app: {
        appName: "encuentralo.energia",
        appTitle: "encuentralo.energia",
      },
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  private insertMainScript() {
    const script: HTMLScriptElement = this.renderer2.createElement("script");
    script.type = "text/javascript";
    script.text = `
        document.addEventListener("DOMContentLoaded", function (event) {
        window._tfa = window._tfa || [];
        window._tfa.push({ notify: "event", name: "page_view", id: 1757722 });
        !(function (t, f, a, x) {
          if (!document.getElementById(x)) {
            t.async = 1;
            t.src = a;
            t.id = x;
            f.parentNode.insertBefore(t, f);
          }
        })(
          document.createElement("script"),
          document.getElementsByTagName("script")[0],
          "//cdn.taboola.com/libtrc/unip/1757722/tfa.js",
          "tb_tfa_script"
        );
      });
    `;
    this.renderer2.appendChild(this._document.body, script);
  }

  ngOnInit(): void {
    //this.insertMainScript();
  }
}
