<header class="header-area overlay">
  <nav class="navbar navbar-expand-md">
    <div class="container">
      <img
        src="https://ad.uinterbox.com/con18298-logo/image019.png"
        class="navbar-brand img-fluid"
      />
      <strong class="text-bolder text-black"
        >¡Tu recomendación de manera gratuita en menos de 1 minuto!</strong
      >
    </div>
  </nav>
</header>
<div class="vh-100">
  <router-outlet></router-outlet>
</div>
