import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { JsonFormData } from '@core/components/core-json-form/model/json-form-data';
import { JsonSettingsData } from '@core/components/core-json-settings-page/model/json-settings-data';
import { NgbNavbar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { NetworkSettingsService } from '../service/network-settings.service';

@Component({
  selector: 'app-admin-network-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit {

  // public
  public contentHeader: object;
  
  public formData: JsonFormData;
  public settingsData: JsonSettingsData;

  public active: any;
  @ViewChild('nav', {static: true}) nav: any;

  // private
  private _unsubscribeAll: Subject<any>;

  constructor(private _networkService: NetworkSettingsService) {
    this._unsubscribeAll = new Subject();
  }

  navChangeEvent(e: any) {
    console.log(e);
    
  }

  ngOnInit(): void {
    // content header
    this.contentHeader = {
      headerTitle: 'Network Settings',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'network',
            isLink: false
          },
          {
            name: 'Settings',
            isLink: false
          }
        ]
      }
    };

    //get information for the first pill
    this._networkService.getSettingsPage().subscribe((settingsData: JsonSettingsData) => {
      this.settingsData = settingsData;
      
    });

    // this.nav.activeIdChange.subscribe(d => {console.log(d);
    // })
  }

  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
