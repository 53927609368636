<div class="d-flex">
  <div class="container">
    <div class="d-flex p-lg-5 flex-sm-row vh-70" id="main-sec" #target>
      <div class="d-flex flex-column">
        <div *ngIf="selectedOption">
          <h1 class="mt-5 text-white font-size-52 mx-1 font-weight-bolder">
            Ahorra hasta 270€ en tus facturas de {{ selectedOption }}
          </h1>
          <ul class="list-style-icons">
            <li class="text-white font-weight-bolder">
              <span
                class="text-warning"
                [data-feather]="'chevron-right'"
              ></span>
              Compara tarifas de las mejores compañias
            </li>
            <li class="text-white font-weight-bolder">
              <span
                class="text-warning"
                [data-feather]="'chevron-right'"
              ></span>
              Ahorra 270€ anuales en tu factura
            </li>
            <li class="text-white font-weight-bolder">
              <span
                class="text-warning"
                [data-feather]="'chevron-right'"
              ></span>
              Recibe asesoramiento gratuito
            </li>
          </ul>
        </div>
        <div *ngIf="!selectedOption">
          <h1 class="mt-5 text-white font-size-52 mx-1 font-weight-bolder">
            El mejor comparador de tarifas del mercado
          </h1>
          <ul class="list-style-icons">
            <li class="text-white font-weight-bolder">
              <span
                class="text-warning"
                [data-feather]="'chevron-right'"
              ></span>
              Encuéntralo.energía es un servicio gratuito y sin compromiso a
              través del cual varios proveedores de energía compiten para
              ofrecerte la mejor tarifa para ti.
            </li>
          </ul>
        </div>
        <div class="d-flex flex-column">
          <form [formGroup]="selectorForm" (ngSubmit)="onSubmit()">
            <div class="card mt-2 ml-1 position-absolute">
              <div
                class="card-body m-2 p-50"
                style="padding-bottom: 0px !important"
              >
                <h1 class="my-1">¿Cuál es tu proveedor actual de servicios?</h1>
                <div class="my-1">
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Repsol"
                    />
                    Repsol
                  </label>
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Iberdrola"
                    />
                    Iberdrola
                  </label>
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Endesa"
                    />
                    Endesa
                  </label>
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Naturgy"
                    />
                    Naturgy
                  </label>
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Hola Luz"
                    />
                    HolaLuz
                  </label>
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Nexus"
                    />
                    Nexus Energía
                  </label>
                  <label class="mx-3">
                    <input
                      type="radio"
                      class="option-input radio"
                      formControlName="company"
                      value="Otras"
                    />
                    Otras
                  </label>
                </div>
                <div class="d-flex flex-sm-row">
                  <div class="form-group mb-0">
                    <!-- <label for="typeOfClient"></label>
                    <select
                      id="typeOfClient"
                      class="form-control form-control-lg"
                      formControlName="typeOfClient"
                    >
                      <option value="">Tipo de cliente</option>
                      <option value="Peninsula">Particular</option>
                      <option value="Baleares">Empresa</option>
                    </select> -->
                  </div>
                  <div class="form-group mb-0">
                    <!-- <label for="selectedArea"></label>
                    <select
                      id="selectedArea"
                      class="form-control form-control-lg"
                      formControlName="area"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Superficie
                      </option>
                      <option value="50">Menos de 50m²</option>
                      <option value="90">Sobre los 90m²</option>
                      <option value="100">+ 100m²</option>
                    </select> -->
                  </div>
                  <div class="form-group mb-0">
                    <!-- <label for="selectProvince"></label>
                    <select
                      id="selectProvince"
                      class="form-control form-control-lg"
                      formControlName="province"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Provincia
                      </option>
                      <option value="Peninsula">Península</option>
                      <option value="Baleares">Baleares</option>
                      <option value="Canarias">Canarias</option>
                    </select> -->
                  </div>
                  <div class="form-group mb-0">
                    <!-- <label for="selectedCompany"></label>
                    <select
                      id="selectedCompany"
                      class="form-control form-control-lg"
                      formControlName="company"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Compañía actual
                      </option>
                      <option value="Iberdrola">Iberdrola</option>
                      <option value="Naturgy">Naturgy</option>
                      <option value="EDP">EDP</option>
                      <option value="Imagina energía">Imagina energía</option>
                      <option value="Endesa">Endesa</option>
                      <option value="Repsol">Repsol</option>
                      <option value="Hola Luz">Hola Luz</option>
                      <option value="Nexus Energía">Nexus Energía</option>
                      <option value="Podo">Podo</option>
                      <option value="Otra compañia">Otra compañia</option>
                    </select> -->
                  </div>
                  <!-- <button
                    type="submit"
                    class="btn btn-warning mt-2"
                    rippleEffect
                  >
                    Ver tarifas
                  </button> -->
                </div>
                <div class="d-flex pt-50 align-items-center">
                  <span
                    class="text-warning mr-50"
                    [data-feather]="'lock'"
                  ></span>
                  <a
                    (click)="openPrivacy(privacy)"
                    class="text-warning text-bolder"
                    >Protegemos tu privacidad.</a
                  >
                </div>
              </div>
            </div>
            <div
              class="btn-group btn-group-toggle ml-3 mr-auto radio-buttons-list"
              ngbRadioGroup
              name="radioBasic"
              formControlName="selectedOption"
            >
              <label ngbButtonLabel class="btn btn-lg btn-light" rippleEffect>
                <input ngbButton type="radio" [value]="'gas'" />Gas
              </label>
              <label ngbButtonLabel class="btn btn-lg btn-light" rippleEffect>
                <input ngbButton type="radio" [value]="'luz'" />Luz
              </label>
              <label ngbButtonLabel class="btn btn-lg btn-light" rippleEffect>
                <input ngbButton type="radio" [value]="'luz+gas'" />Ambas
              </label>
              <label ngbButtonLabel class="btn btn-lg btn-light" rippleEffect>
                <input
                  ngbButton
                  type="radio"
                  [value]="'placas solares'"
                />Placas solares
              </label>
            </div>
          </form>
        </div>
      </div>
      <div class="p-2 justify-contend-end ml-auto mt-4 main-img d-none">
        <img
          src="/assets/images/illustration/12932899.jpg"
          width="580"
          class="rounded"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
<div class="d-flex first-section-container">
  <div class="bg-white w-100">
    <div class="container">
      <div class="card">
        <div class="card-header text-center">
          <h1
            class="mt-5 font-size-52 font-weight-bolder"
            style="max-width: 100% !important"
          >
            Encuentra las tarifas de luz y gas que mejor se adaptan a tu estilo
            de vida.
          </h1>
        </div>
        <div class="card-body">
          <p>
            Comparamos las diferentes tarifas de suministro para ayudarte a
            elegir la mejor oferta del mercado y ahorrar en tus facturas.
            Descubre qué compañía de electricidad es la más barata y cuál es la
            mejor tarifa de luz de 2023.<br />
            Encuentra las tarifas de luz y gas que mejor se adáptate a las
            necesidades de tu hogar o negocio entre las compañías líderes del
            mercado hasta encontrar la mejor oferta para ti.<br />
            Es gratis, rápido y fácil. ¡Empieza ya a comparar entre las últimas
            tarifas de las mejores comercializadoras! Te ayudamos con todas las
            dudas que puedan surgirte al cambiar de compañía, dar de alta la luz
            y el gas, gestionar tu contrato de suministro, entender las facturas
            de energía y los consejos que de verdad funcionan para ahorrar más
            cada mes en tu consumo particular o en el de tu empresa.<br />
            Aprovecha cada mes las ofertas especiales que sólo te
            proporcionaremos en encuéntralo. Energía, y contrátalas por tiempo
            limitado con cada compañía directamente. <br />
            <br />
          </p>
          <strong
            >¡Con encuéntralo ahorrar unos 270 euros en tu factura de
            luz!</strong
          >
        </div>
        <div class="card-footer text-center">
          <button
            class="btn btn-warning btn-lg"
            (click)="scroll(target); selectedOption = 'luz'"
          >
            Descubre cómo pagar menos luz
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <div class="bg-sec-1 w-100">
    <div class="container">
      <div class="card" style="font-weight: bolder !important">
        <div class="card-header text-center">
          <h1
            class="mt-5 font-size-52 font-weight-bolder text-warning"
            style="max-width: 100% !important"
          >
            Ahorrar energía con una buena tarifa
          </h1>
        </div>
        <div class="card-body">
          <p class="">
            Para evitar gastar más energía de la que necesitas, y no tener que
            pagar tanto por el suministro de electricidad y gas, es muy
            importante acertar con una tarifa de suministros ajustada a tu
            perfil de consumo. ¿cuál es la mejor tarifa de electricidad? ¿Y de
            gas? ¿Es mejor contratar ambos suministros con la misma compañía?<br /><br />
            El sector de la energía es un mercado dinámico en el que las
            comercializadoras intentan atraer clientes con descuentos y ofertas,
            pero conviene no descuidarse porque a veces una oferta atractiva
            deja de serlo al cabo de unos meses cuando se aplican las
            actualizaciones de precio. Por eso, te recomendamos revisar con
            frecuencia lo que pagas y comprobar usando el comparador si tu
            tarifa de gas o luz es ventajosa.
          </p>
          <strong class="mt-2">Es fácil cambiar de proveedor de energía</strong>
          <ul class="mt-50">
            <li class="my-50">
              <strong
                >Rellena el formulario con tus datos y preferencias</strong
              >
              para que varios proveedores de luz y gas de tu zona se pongan en
              contacto contigo.
            </li>
            <li class="my-50">
              <strong>Las diferentes empresas compiten</strong> por tenerte como
              cliente, por lo que te ofrecerán sus mejores tarifas de luz y/o
              gas según las preferencias que hayas indicado en el formulario.
            </li>
            <li class="my-50">
              Una vez hayas recibido las diferentes ofertas,
              <strong>tú decides con total libertad.</strong> Puedes aceptar la
              oferta que más se ajuste a tus necesidades. Además, nuestro
              servicio es sin compromiso: si ninguna de las tarifas te convence,
              puedes declinarlas sin ningún problema.
            </li>
          </ul>
        </div>
        <div class="card-footer text-center">
          <button
            class="btn btn-warning btn-lg"
            (click)="scroll(target); selectedOption = 'luz'"
            rippleEffect
          >
            Descubre cómo pagar menos luz
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <div class="bg-white w-100">
    <div class="container">
      <div class="card">
        <div class="card-header text-center">
          <h1
            class="mt-5 font-size-52 font-weight-bolder"
            style="max-width: 100% !important"
          >
            ¿Qué tipo de energía te interesa?
          </h1>
        </div>
        <div class="card-body">
          <p>
            <strong>Encuéntralo.Energía,</strong> Te permite encontrar las
            mejores tarifas de luz y gas en tu zona. En nuestro formulario
            puedes elegir entre tres opciones:
          </p>
          <ul class="mt-50">
            <li class="my-50">
              <strong>Luz y gas:</strong> Elige esta opción si quieres contratar
              una tarifa de luz y gas con el mismo proveedor. Algunas compañías
              ofrecen una sola tarifa que combina los dos suministros, mientras
              que otras ofrecen dos tarifas diferentes administradas de forma
              conjunta. En cualquier caso, contratar la luz y el gas con el
              mismo proveedor te permite ahorrar en la factura de la forma más
              cómoda.
            </li>
            <li class="my-50">
              <strong>Sólo luz:</strong> Elige esta opción si solamente quieres
              contratar una tarifa de electricidad. Además, en la pregunta: ¿Qué
              tipo de tarifa te interesa?, puedes marcar la opción de 'Tarifas
              de energía verde' si quieres recibir ofertas de fuentes de energía
              que tienen un menor impacto medioambiental.
            </li>
            <li class="my-50">
              <strong>Sólo gas:</strong> Elige esta opción si solamente te
              interesa recibir ofertas de gas. Si tienes alguna preferencia
              específica sobre qué tipo de tarifa quieres o si deseas contratar
              un servicio de mantenimiento de tu caldera o calentador, puedes
              utilizar el campo de Información adicional para indicar esta
              información.
            </li>
            <li class="my-50">
              <strong>Placas Solares:</strong> Elige está opción si solo te
              interesa recibir ofertas de compañías que te ayudarán con la
              instalación de las placas solares en tu casa, pasa ahorrar hasta
              un 100% en la factura de luz en tu casa.
            </li>
          </ul>
        </div>
        <div class="card-footer text-center">
          <a
            class="btn btn-warning btn-lg"
            (click)="scroll(target); selectedOption = 'luz'"
            rippleEffect
            >Descubre cómo pagar menos luz</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center">
  <div class="bg-white w-100">
    <div class="d-flex justify-content-center">
      <div class="footer-link">
        <a [routerLink]="['/landings/legal-notice']" target="_blank">
          Aviso legal
        </a>
        <span class="ml-50 mr-50"> - </span>
        <a [routerLink]="['/landings/privacy-policy']" target="_blank">
          Política de privacidad
        </a>
        <span class="ml-50 mr-50"> - </span>
        <a [routerLink]="['/landings/cookie-policy']" target="_blank">
          Política de cookies
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #privacy let-modal>
  <div class="modal-header text-center bg-white">
    <h1 class="modal-title">Tu Privacidad es Importante para Nosotros</h1>
  </div>
  <div class="modal-body">
    <app-landings-privacy-policy></app-landings-privacy-policy>
    <a
      class="btn btn-warning btn-lg mb-1"
      (click)="modal.dismiss('Cross click')"
    >
      Acepto
    </a>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header text-center bg-white">
    <h1 class="modal-title">Tu Privacidad es Importante para Nosotros</h1>
  </div>
  <div class="modal-body">
    <p>
      Encuentralo.energia utiliza cookies propias y de terceros que se instalan
      en su equipo para realizar análisis de uso y medición del Web para mejorar
      nuestros servicios y facilitar su navegación.<br />
      Puede aceptar las cookies pulsando en el botón "Acepto" o configurarlas o
      rechazar su uso, obteniendo más información clicando en "Más opciones". Si
      desea saber más sobre nuestras cookies, haga click
      <strong
        ><a
          class="text-warning text-bolder"
          [routerLink]="['/landings/cookie-policy']"
          target="_blank"
          >aquí</a
        ></strong
      >
    </p>
    <div class="d-flex flex-column" *ngIf="hidden">
      <h3 class="text-muted">Selecciona qué cookies quieres aceptar</h3>
      <div class="custom-control custom-checkbox custom-control-warning">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customCheck2"
          checked
          disabled
        />
        <label class="custom-control-label" for="customCheck2"
          >Necesarias</label
        >
      </div>
      <div class="custom-control custom-checkbox custom-control-warning">
        <input type="checkbox" class="custom-control-input" id="customCheck2" />
        <label class="custom-control-label" for="customCheck2"
          >Preferencias</label
        >
      </div>
      <div class="custom-control custom-checkbox custom-control-warning">
        <input type="checkbox" class="custom-control-input" id="customCheck2" />
        <label class="custom-control-label" for="customCheck2"
          >Analíticas</label
        >
      </div>
      <div class="custom-control custom-checkbox custom-control-warning">
        <input type="checkbox" class="custom-control-input" id="customCheck2" />
        <label class="custom-control-label" for="customCheck2">Marketing</label>
      </div>
    </div>
    <div class="d-flex flex-column my-1">
      <a
        class="btn btn-warning btn-lg mb-1"
        (click)="modal.dismiss('Cross click')"
      >
        Acepto
      </a>
      <a
        class="btn btn-danger btn-lg mb-1"
        (click)="modal.dismiss('Cross click')"
      >
        Rechazo
      </a>
      <button
        class="btn btn-outline-secondary btn-lg"
        (click)="hidden = !hidden"
      >
        Más opciones
      </button>
    </div>
  </div>
</ng-template>
